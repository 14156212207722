import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Text from '@frontend/ui-kit/Components/Text';
import Table from '@frontend/ui-kit/Components/Table';
import Link from '@frontend/ui-kit/Components/Link';
import {requestExpandedSearchContext} from '../../../actions/providers';
import {getAccessToken} from '../../../selectors/authorization';
import {getSpecialtyName, getValueCell, isInternalNpi} from '../../../helpers';
import {isEmpty, stringifyURL} from '../../../utils';
import {API_BASE_URL, VENDOR_TYPES, ROUTES} from '../../../constants';
import {PROVIDER_BASKET_ENDPOINT} from '../../../apiServices/constants';

const PROVIDER_RECORDS_URL = `${API_BASE_URL}${PROVIDER_BASKET_ENDPOINT}/records`;

const SearchContextExpanded = props => {
    const {searchId} = props;
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [providers, setProviders] = useState();
    const accessToken = useSelector(getAccessToken);

    useEffect(() => {
        (async () => {
            const {providers} = await dispatch(requestExpandedSearchContext(searchId));

            setProviders(providers);
            setIsLoaded(true);
        })();
    }, [searchId, dispatch]);

    const tableColumns = [
        {
            Header: 'NPI / Internal ID',
            accessor: 'npi',
            Cell: item => {
                const url = stringifyURL({
                    url: `${window.location.origin}${ROUTES.providerRecords}/${item.value}`,
                    query: {
                        ...(isInternalNpi(item.value) && {search_id: searchId})
                    }
                });

                return <Link href={url} target='_blank'>{item.value}</Link>;
            }
        },
        {Header: 'Name', accessor: 'name', Cell: getValueCell},
        {Header: 'Type', accessor: 'type', Cell: getValueCell},
        {Header: 'Specialties', accessor: 'specialties', Cell: item => <Text className='white-space-normal'>{!isEmpty(item?.value) ? item.value.map(getSpecialtyName).join(', ') : '-'}</Text>},
        {Header: 'In-network', accessor: 'is_in_network', Cell: item => <Text className='white-space-normal'>{item.value ? 'Yes' : '-'}</Text>},
        {
            Header: 'Actions',
            Cell: cell => {
                const {row: {original}} = cell;
                const {npi} = original;
                const ideonProviderRecordUrl = `${PROVIDER_RECORDS_URL}/${VENDOR_TYPES.ideon}/${npi}?access_token=${accessToken}`;
                const ribbonProviderRecordUrl = `${PROVIDER_RECORDS_URL}/${VENDOR_TYPES.ribbon}/${npi}?access_token=${accessToken}`;

                return (
                    <React.Fragment>
                        {!isInternalNpi(npi) && (
                            <div>
                                <Link className='mb-4 mr-2' href={ideonProviderRecordUrl} target='_blank' isButton>Ideon (Vericred)</Link>
                                <Link href={ribbonProviderRecordUrl} target='_blank' isButton>Ribbon</Link>
                            </div>
                        )}
                        {isInternalNpi(npi) && (
                            <span>-</span>
                        )}
                    </React.Fragment>
                );
            }
        }
    ];

    const tableProps = {
        data: providers,
        columns: tableColumns,
        isCellTooltip: false,
        isFilterable: false,
        isSortable: false
    };

    return isLoaded ? <Table {...tableProps}/> : null;
};

SearchContextExpanded.propTypes = {
    searchId: PropTypes.number
};

export {SearchContextExpanded as TestableSearchContextExpanded};
export default React.memo(SearchContextExpanded);
