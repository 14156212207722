import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Loader from '../../Components/shared/Loader';
import {requestJourney} from '../../actions/decisionCenter';
import {parseQuery, stringifyQueryParams} from '../../utils';

// FYI: For displaying CRM sidebar, URL must contain query parameter "customerId" when page load.
// That's why we should add it and reload page. (Slava, 28.09.2021)
const withCRMSidebar = Component => {
    const WithCRMSidebar = props => {
        const {pathname, search} = useLocation();
        const {journeyId} = useParams();
        const dispatch = useDispatch();
        const [isLoaded, setIsLoaded] = useState(false);
        const {customerId} = parseQuery(search);

        useEffect(() => {
            (async () => {
                if (customerId) {
                    setIsLoaded(true);

                    return;
                }

                const {customerId: journeyCustomerId} = await dispatch(requestJourney(journeyId));

                if (!journeyCustomerId) {
                    return;
                }

                const updatedQueryParams = stringifyQueryParams({customerId: journeyCustomerId});

                window.location.href = `${pathname}?${updatedQueryParams}`;
            })();
        }, [journeyId, customerId, pathname, dispatch]);

        const componentProps = {...props, journeyId, customerId};

        return isLoaded ? <Component {...componentProps}/> : <Loader/>;
    };

    return WithCRMSidebar;
};

export {withCRMSidebar as testableWithCRMSidebar};
export default withCRMSidebar;
