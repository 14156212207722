import React from 'react';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';
import Container from '@frontend/ui-kit/Components/Container';
import Notification from './Components/shared/Notification';
import GlobalError from './Components/shared/Error/GlobalError';
import store from './store';
import Router from './Router';
import {GLOBAL_ERRORS_MESSAGES} from './constants';

const App = () => {
    const onBeforeCapture = scope => {
        scope.setLevel('fatal');
    };

    const errorFallback = ({error}) => {
        const globalErrorMessage = GLOBAL_ERRORS_MESSAGES[Object.keys(GLOBAL_ERRORS_MESSAGES).find(key => error.message.includes(key))];
        const errorMessage = globalErrorMessage || error.message;
        const updatedError = {...error, message: errorMessage};

        return <GlobalError error={updatedError}/>;
    };

    return (
        <Sentry.ErrorBoundary fallback={errorFallback} beforeCapture={onBeforeCapture}>
            <Provider store={store}>
                <Container fluid>
                    <React.Fragment>
                        <Notification/>

                        <Router/>
                    </React.Fragment>
                </Container>
            </Provider>
        </Sentry.ErrorBoundary>
    );
};

export default App;
