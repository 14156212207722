import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';

const MAPS_TRAVEL_MODE = {
    bicycling: 'BICYCLING',
    driving: 'DRIVING',
    transit: 'TRANSIT',
    two_wheeler: 'TWO_WHEELER',
    walking: 'WALKING'
};

const MAPS_UNIT_SYSTEM = {
    imperial: 1,
    metric: 0
};

const getFlatDistances = ({distance, duration}) => ({distance: distance?.text, duration: duration?.text});

const getDistanceToLocation = async (origins = [], destinations = []) => {
    const distanceMatrixService = new window.google.maps.DistanceMatrixService();
    let distancesData;

    try {
        const {rows = [{}]} = await distanceMatrixService.getDistanceMatrix({
            origins,
            destinations,
            travelMode: MAPS_TRAVEL_MODE.driving,
            unitSystem: MAPS_UNIT_SYSTEM.imperial
        });

        distancesData = rows;
    } catch (error) {
        distancesData = [{}];
    }

    const [{elements: distances = []}] = distancesData;
    const formattedDistances = distances.map(getFlatDistances);

    return formattedDistances;
};

const DistanceChecking = props => {
    const {startPoint, endPoint, className} = props;
    const [distance, setDistance] = useState('');
    const isButtonDisabled = !startPoint || !endPoint;

    useEffect(() => {
        setDistance('');
    }, [startPoint, endPoint]);

    const onCheckDistance = async () => {
        const [{distance}] = await getDistanceToLocation([startPoint], [endPoint]);

        setDistance(distance);
    };

    return (
        <div className={classnames('distance-checking', className)}>
            {!distance && (
                <Button type={BUTTON_TYPES.tertiary} disabled={isButtonDisabled} onClick={onCheckDistance}>
                    Check
                    <Icon type={ICON_TYPES.arrowRight}/>
                </Button>
            )}

            {distance && (
                <Text isInline isSmall type={TEXT_TYPES.bodyBold}>{distance}</Text>
            )}
        </div>
    );
};

DistanceChecking.propTypes = {
    startPoint: PropTypes.string,
    endPoint: PropTypes.string,
    className: PropTypes.string
};

export {DistanceChecking as TestableDistanceChecking};
export default React.memo(DistanceChecking);
