import React, {useState, useEffect} from 'react';
import {getScript} from '../../utils';
import {GOOGLE_MAPS_API_URL, GOOGLE_API_KEY} from '../../constants';

const MAPS_SCRIPT_SOURCE = `${GOOGLE_MAPS_API_URL}/api/js?v=beta&key=${GOOGLE_API_KEY}`;

const withLoadingMaps = Component => {
    return props => {
        const [isLoaded, setIsLoaded] = useState(false);

        const loadMaps = source => new Promise(resolve => {
            getScript(source, () => resolve());
        });

        useEffect(() => {
            (async () => {
                if (!window?.google?.maps) {
                    await loadMaps(MAPS_SCRIPT_SOURCE);
                }
                setIsLoaded(true);
            })();
        }, []);

        return isLoaded ? <Component {...props}/> : null;
    };
};

export default withLoadingMaps;
