import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import withPopup from '../../../HOC/withPopup';
import {saveDraftData, getDraftData, deleteDraftData} from '../../../actions/shared';
import {requestServiceDetailList, requestServiceDetailAdding, requestServiceDetailDeleting} from '../../../actions/providers';
import {getServiceDetails as getServiceDetailList, getActiveNpi} from '../../../selectors/providers';
import {formatDate, getUtcToZonedTime, equal, compose, isEmpty, getEqual, negateFunc, getSplittedText} from '../../../utils';
import {DRAFT_ITEMS} from '../../../constants';
import styles from './index.module.scss';

const POPUP_ID = 'serviceDetailsPopup';
const NEW_SERVICE_DETAIL_ID = Symbol('new_service_detail_id');

const ServiceDetails = props => {
    const {openPopup, closePopup} = props;
    const dispatch = useDispatch();
    const activeNpi = useSelector(getActiveNpi);
    const serviceDetailList = useSelector(getServiceDetailList);
    const [serviceDetails, setServiceDetails] = useState([]);
    const reversedServiceDetails = useMemo(() => [...serviceDetails].reverse(), [serviceDetails]);
    const [isAllDetailsShown, setIsAllDetailsShown] = useState(false);
    const {id: lastServiceDetailId} = serviceDetails[serviceDetails.length - 1] ?? {};
    const isAddableServiceDetails = lastServiceDetailId || isEmpty(serviceDetails);

    useEffect(() => {
        (async () => {
            if (!activeNpi) {
                return;
            }

            const {serviceDetails} = serviceDetailList[activeNpi] ? {serviceDetails: serviceDetailList[activeNpi]} : await dispatch(requestServiceDetailList(activeNpi));
            const draftServiceDetails = await dispatch(getDraftData(DRAFT_ITEMS.draftServiceDetailList, activeNpi)) ?? {};
            const updatedServiceDetails = serviceDetails.map(serviceDetail => ({
                ...serviceDetail,
                text: draftServiceDetails && draftServiceDetails[serviceDetail.id] ? draftServiceDetails[serviceDetail.id] : serviceDetail.text
            }));

            setServiceDetails(updatedServiceDetails);
        })();
    }, [activeNpi]);

    const getServiceDetail = (serviceDetail, index) => {
        const {id: serviceDetailId, updated, updated_by: updatedBy, text} = serviceDetail;
        const isLast = equal(index, serviceDetails.length - 1);
        const isNewServiceDetail = equal(serviceDetailId, NEW_SERVICE_DETAIL_ID);

        const onChangeText = async ({target}) => {
            const {value} = target;
            const updatedServiceDetails = serviceDetails.map((serviceDetail => (
                {...serviceDetail, text: equal(serviceDetail.id, serviceDetailId) ? value : serviceDetail.text}
            )));

            if (!isNewServiceDetail) {
                // FYI: Even when several Service Details was changed, need to save only the last (Slava, 19.07.2023)
                dispatch(saveDraftData(DRAFT_ITEMS.draftServiceDetailList, activeNpi, {[serviceDetailId]: value}));
            }

            setServiceDetails(updatedServiceDetails);
        };

        const onSaveServiceDetail = async () => {
            const {serviceDetail: updatedServiceDetail} = await dispatch(requestServiceDetailAdding(activeNpi, {text}));
            const updatedServiceDetails = serviceDetails.map(serviceDetail => {
                return equal(serviceDetail.id, NEW_SERVICE_DETAIL_ID) ? updatedServiceDetail : serviceDetail;
            });

            setServiceDetails(updatedServiceDetails);
            dispatch(deleteDraftData(DRAFT_ITEMS.draftServiceDetailList, activeNpi));
        };

        const onOpenDeletingPopup = () => {
            const onDeleteServiceDetail = async () => {
                const {isSuccess} = await dispatch(requestServiceDetailDeleting(activeNpi, serviceDetailId));

                closePopup();

                if (isSuccess) {
                    const filteredServiceDetails = serviceDetails.filter(negateFunc(getEqual(serviceDetailId, 'id')));

                    dispatch(deleteDraftData(DRAFT_ITEMS.draftServiceDetailList, activeNpi));
                    setServiceDetails(filteredServiceDetails);
                }
            };

            const actionBar = (
                <React.Fragment>
                    <Button type={BUTTON_TYPES.primary} className='mr-5' onClick={onDeleteServiceDetail}>Yes</Button>
                    <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                </React.Fragment>
            );

            const popupContent = <Text isInline>Are you sure you want to delete it?</Text>;
            const popupProps = {title: 'Delete service detail', actionBar, children: popupContent};
            const children = <PopupContent {...popupProps}/>;

            return openPopup({modalType: 'simple', children});
        };

        const getLineBrickedText = value => {
            if (!value || !value.includes('\n')) {
                return value;
            }
            const lines = getSplittedText(value);

            return lines.map(line => (<div>{line}</div>));
        };

        return (
            <Row middle='sm' key={index} data-testid='service-detail'>
                <Column xs={10} className='mb-6'>
                    {isNewServiceDetail && (
                        <Textarea value={text} rows={5} onChange={onChangeText} label='Comment'/>
                    )}

                    {!isNewServiceDetail && (
                        <Text>{getLineBrickedText(text)}</Text>
                    )}

                    {updated && updatedBy && (
                        <Text data-testid='updated-information' className='mt-3 mb-5' isSmall>
                            added on
                            &nbsp;
                            <Text isInline type={TEXT_TYPES.bodyBold}>{formatDate(getUtcToZonedTime(updated), 'MM/dd/yyyy h:mm aa z')}</Text>
                            &nbsp;
                            by <Text isInline type={TEXT_TYPES.bodyBold}>{updatedBy}</Text>
                        </Text>
                    )}
                </Column>
                {!isNewServiceDetail && (
                    <Column sm={2}>
                        <Icon data-testid='delete-service-detail' type={ICON_TYPES.delete} className={styles.removeIcon} onClick={onOpenDeletingPopup}/>
                    </Column>
                )}
                <Column xs={12}>
                    <Row end='xs'>
                        {isNewServiceDetail && (
                            <Button data-testid='save-service-detail' type={BUTTON_TYPES.secondary} disabled={!text} onClick={onSaveServiceDetail}>Save</Button>
                        )}
                    </Row>
                </Column>

                {!isLast && <Separator/>}
            </Row>
        );
    };

    const onAddServiceDetail = () => setServiceDetails([...serviceDetails, {text: '', id: NEW_SERVICE_DETAIL_ID}]);
    const onShowAllDetails = () => setIsAllDetailsShown(!isAllDetailsShown);

    return (
        <ContentSection>
            <Row between='xs' middle='xs'>
                <Column className='mb-15'>
                    <Heading type={HEADING_TYPES['4']}>Service Notes</Heading>
                </Column>
            </Row>

            <Button type={BUTTON_TYPES.secondary} disabled={!isAddableServiceDetails} onClick={onAddServiceDetail} className='mb-20 w-full'>
                + Add internal note
            </Button>

            {!isEmpty(reversedServiceDetails) && (
                <div className=''>
                    {reversedServiceDetails.slice(0, 5).map(getServiceDetail)}

                    {!isAllDetailsShown && reversedServiceDetails.length >= 6 && (
                        <Row onClick={onShowAllDetails}>
                            <Column sm={11}>
                                <Text type={TEXT_TYPES.bodyBold}>View additional notes</Text>
                            </Column>
                            <Column sm={1}>
                                <Icon type={ICON_TYPES.chevronDown}/>
                            </Column>
                        </Row>
                    )}

                    {isAllDetailsShown && (
                        <div className=''>
                            {reversedServiceDetails.slice(5, reversedServiceDetails.length).map(getServiceDetail)}
                        </div>
                    )}
                </div>
            )}
        </ContentSection>
    );
};

ServiceDetails.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {ServiceDetails as TestableServiceDetails, NEW_SERVICE_DETAIL_ID};
export default compose(
    React.memo,
    withPopup(POPUP_ID)
)(ServiceDetails);
