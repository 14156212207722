import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import Select from '@frontend/ui-kit/Components/Select';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import {Field} from '../../shared/FormComponents';
import TemplatePicker from '../../shared/TemplatePicker';
import Procedure from '../Procedure';
import AppointmentDate from '../AppointmentDate';
import {
    getCardTemplates,
    getPossibleRewardsPrograms,
    getIsTPAUser
} from '../../../selectors/decisionCenter';
import useForm from '../../../hooks/useForm';
import {getOfficeName} from '../../../helpers';
import {CARD_TEMPLATE_CATEGORIES} from '../../../constants';
import {DEFAULT_OPTION, NETWORK_OPTIONS, PRE_CERTIFICATION_EVENT_OPTIONS} from '../../../options';

const DecisionDetails = props => {
    const {prefixName, offices, isProcedureExists, isAppointmentAvailable, areMultiOfficesIncluded, isPreCertEvent} = props;
    const form = useForm();
    const [isProcedure, setIsProcedure] = useState(isProcedureExists);
    const cardTemplates = useSelector(getCardTemplates);
    const isTPAUser = useSelector(getIsTPAUser);
    const possibleRewardsPrograms = useSelector(getPossibleRewardsPrograms);

    const rewardsProgramsOptions = possibleRewardsPrograms.map(({id, name}) => ({value: id, label: name}));
    const updatedRewardsProgramsOptions = [DEFAULT_OPTION, ...rewardsProgramsOptions];
    const officesOptions = offices.map(({id, ...restOffice}, index) => ({value: id, label: getOfficeName({...restOffice, id}, index)}));

    const onToggleProcedure = () => {
        setIsProcedure(!isProcedure);

        if (isProcedure) {
            form.change(`${prefixName}.details.procedure`, {});
        }
    };

    return (
        <div className='decision-details'>
            <Heading className='mb-15' type={HEADING_TYPES['4']}>Decision details</Heading>

            <Field name={`${prefixName}.kind`}>{props => <Input {...props} type='hidden'/>}</Field>

            <Row>
                <Column sm={4} className='mb-10'>
                    <Field name={`${prefixName}.is_recommended`}>{props => <Checkbox {...props} caption='Is recommended'/>}</Field>
                </Column>
                <Column sm={6} className='mb-10'>
                    <Field name={`${prefixName}.details.is_in_network`}>
                        {props => (
                            <Select {...props}
                                label='Is in network'
                                options={NETWORK_OPTIONS}
                                placeholder='Network not applicable'/>
                        )}
                    </Field>
                </Column>

                <Column sm={12} className='mb-12'>
                    <Field name={`${prefixName}.rewards_program_id`}>
                        {props => <Select {...props} label='Rewards program' options={updatedRewardsProgramsOptions}/>}
                    </Field>
                </Column>
            </Row>

            <Field name={`${prefixName}.details.npi`}>{props => <Input {...props} type='hidden'/>}</Field>
            <Field name={`${prefixName}.details.type`}>{props => <Input {...props} type='hidden'/>}</Field>

            <Row>
                <Column sm={12} className='mb-12'>
                    <Field name={`${prefixName}.details.why_recommend`}>
                        {props => <Textarea {...props} label='Why recommended' maxLength={255} rows={3}/>}
                    </Field>
                    <TemplatePicker field={`${prefixName}.details.why_recommend`}
                        className='mt-12'
                        templates={cardTemplates[CARD_TEMPLATE_CATEGORIES.whyRecommend]}
                        placeholder='Select a "Why recommended" template'/>
                </Column>

                <Column sm={12}>
                    <Field name={`${prefixName}.details.provider_highlight`}>
                        {props => <Textarea {...props} label='Public Provider Highlights' maxLength={255} rows={3}/>}
                    </Field>
                    <TemplatePicker field={`${prefixName}.details.provider_highlight`}
                        className='mt-12'
                        templates={cardTemplates[CARD_TEMPLATE_CATEGORIES.providerHighlight]}
                        placeholder='Select a "Public Provider Highlights" template'/>
                </Column>

                <Column sm={12} className='mt-12'>
                    <Field name={`${prefixName}.details.${areMultiOfficesIncluded ? 'office_ids' : 'office_id'}`}>
                        {props => (
                            <Autocomplete {...props}
                                label={`Include ${areMultiOfficesIncluded ? 'offices' : 'office'} in decision`}
                                options={officesOptions}
                                isMulti={areMultiOfficesIncluded}/>
                        )}
                    </Field>
                </Column>
            </Row>

            <Separator/>

            <Row>
                <Column sm={12} className='mb-12'>
                    <Field name={`${prefixName}.details.description`} className='mb-12'>
                        {props => <Textarea {...props} label='Provider notes' maxLength={1024} rows={5}/>}
                    </Field>
                    <TemplatePicker field={`${prefixName}.details.description`}
                        className='mt-12'
                        templates={cardTemplates[CARD_TEMPLATE_CATEGORIES.providerNotes]}
                        placeholder='Select a "Provider notes" template'/>
                </Column>

                <Column sm={12}>
                    <Field name={`${prefixName}.details.is_availability_not_guaranteed`}>
                        {props => <Checkbox {...props} caption='Providers availability not guaranteed'/>}
                    </Field>
                </Column>
            </Row>

            {isTPAUser && (
                <React.Fragment>
                    <Separator type='solid'/>
                    <Row>
                        <Column sm={6} className='mb-12'>
                            <Field name={`${prefixName}.details.is_pre_cert_event`}>
                                {props => <Checkbox {...props} caption='TPA Pre-certification Event'/>}
                            </Field>
                        </Column>

                        {isPreCertEvent && (
                            <React.Fragment>
                                <Column sm={6} className='mb-12'>
                                    <Field name={`${prefixName}.details.pre_cert_provider_changed`}>
                                        {props => <Checkbox {...props} caption='HCC Recommended Provider'/>}
                                    </Field>
                                </Column>

                                <Column sm={6} className='mb-12'>
                                    <Field name={`${prefixName}.details.pre_cert_decision`}>
                                        {props => (
                                            <Select {...props}
                                                label='Pre-Certification Event Decision:'
                                                options={PRE_CERTIFICATION_EVENT_OPTIONS}
                                                placeholder='Select...'/>
                                        )}
                                    </Field>
                                </Column>

                                <Column sm={12} className='mb-12'>
                                    <Field name={`${prefixName}.details.pre_cert_reward_amount`}>
                                        {props => <Input type='number' {...props} label='Pre-Certification Event Reward Amount:'/>}
                                    </Field>
                                </Column>
                            </React.Fragment>
                        )}
                    </Row>
                </React.Fragment>
            )}

            <Separator type='solid'/>

            <Heading className='mb-12' type={HEADING_TYPES['4']}>Insurance coverage</Heading>

            <Row>
                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.details.office_visit`}>
                        {props => <Input {...props} label='Office visit'/>}
                    </Field>
                </Column>
                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.details.preventive_visit`}>
                        {props => <Input {...props} label='Preventive visit'/>}
                    </Field>
                </Column>
                <Column sm={12} className='mb-12'>
                    <Field name={`${prefixName}.details.insurance_notes`}>
                        {props => <Textarea {...props} label='Insurance notes' maxLength={1024} rows={6}/>}
                    </Field>
                    <TemplatePicker field={`${prefixName}.details.insurance_notes`}
                        className='mt-12'
                        templates={cardTemplates[CARD_TEMPLATE_CATEGORIES.insuranceNotes]}
                        placeholder='Select a "Insurance notes" template'/>
                </Column>
            </Row>

            {isProcedure && <Procedure className='mb-12' prefixName={`${prefixName}.details`} cardTemplates={cardTemplates}/>}

            <Row className='mb-12'>
                <Column sm={5}>
                    {isProcedure && <Button type={BUTTON_TYPES.destructive} onClick={onToggleProcedure}>Remove procedure</Button>}

                    {!isProcedure && <Button type={BUTTON_TYPES.secondary} onClick={onToggleProcedure}>Add procedure</Button>}
                </Column>
            </Row>

            {isAppointmentAvailable && (
                <React.Fragment>
                    <Separator type='solid'/>

                    <AppointmentDate prefixName={`${prefixName}.details`}/>
                </React.Fragment>
            )}
        </div>
    );
};

DecisionDetails.propTypes = {
    prefixName: PropTypes.string.isRequired,
    isProcedureExists: PropTypes.bool,
    isPreCertEvent: PropTypes.bool,
    isAppointmentAvailable: PropTypes.bool,
    areMultiOfficesIncluded: PropTypes.bool,
    offices: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, name: PropTypes.string}))
};

DecisionDetails.defaultProps = {
    isPreCertEvent: false
};

export {DecisionDetails as TestableDecisionDetails};
export default React.memo(DecisionDetails);
