import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import styles from './index.module.scss';

const LabeledTooltip = ({title, content, initiator, direction, ...props}) => (
    <React.Fragment>
        <span className={classnames(styles['labeled-tooltip-title'])}>{title}</span>
        {content && (
            <Tooltip content={content} direction={direction} {...props}>
                {initiator || <Icon className={classnames(styles['labeled-tooltip-icon'])} type={ICON_TYPES.info}/>}
            </Tooltip>
        )}
    </React.Fragment>
);

LabeledTooltip.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    initiator: PropTypes.string,
    direction: PropTypes.string
};

export {LabeledTooltip as TestableLabeledTooltip};
export default React.memo(LabeledTooltip);
