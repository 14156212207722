import {combineReducers} from '@reduxjs/toolkit';
import {connectRouter} from 'connected-react-router';
import shared from './shared';
import authorization from './authorization';
import providers from './providers';
import decisionCenter from './decisionCenter';
import history from '../history';

const router = connectRouter(history);

const appReducer = combineReducers({
    router,
    shared,
    authorization,
    providers,
    decisionCenter
});

export default appReducer;
