import apiServices from '../apiServices';
import {
    SET_PROVIDER,
    SET_PROVIDER_LIST,
    SET_ACTIVE_NPI,
    SET_SERVICE_DETAILS,
    SET_NOT_GOOD_OPTIONS,
    SET_ACTIVE_OFFICE
} from './actionTypes';
import {getServiceDetails, getNotGoodOptions} from '../selectors/providers';
import {getFilledWorkingDay, getOfficeName} from '../helpers';
import {
    equal,
    getEqual,
    negateFunc,
    isEmpty,
    getDayNames
} from '../utils';

const sortOfficeByName = (firstOffice, secondOffice) => getOfficeName(firstOffice) > getOfficeName(secondOffice) ? 1 : -1;

const getFormattedWorkingHours = ({working_hours: workingDays = [], ...restOfficeData}) => {
    const transformedWorkingDays = !isEmpty(workingDays) ? workingDays.reduce((acc, day) => ({...acc, [day.day_of_week]: day}), {}) : {};
    const filledWorkingDays = getDayNames().map(getFilledWorkingDay(transformedWorkingDays));

    return {...restOfficeData, working_hours: filledWorkingDays};
};

export const setActiveNpi = activeNpi => ({type: SET_ACTIVE_NPI, activeNpi});

export const setActiveOffice = activeOffice => ({type: SET_ACTIVE_OFFICE, activeOffice});

export const setProviderList = providerList => ({type: SET_PROVIDER_LIST, providerList});

export const setProvider = provider => ({type: SET_PROVIDER, provider});

export const setServiceDetails = serviceDetails => ({type: SET_SERVICE_DETAILS, serviceDetails});

export const setNotGoodOptions = notGoodOptions => ({type: SET_NOT_GOOD_OPTIONS, notGoodOptions});

export const requestProviderList = params => async () => {
    const {data, isSuccess} = await apiServices.getProviderList(params);

    if (!isSuccess) {
        return false;
    }

    const {providers = [], is_last: isLast, is_thirdparty_available: isThirdpartySearchingAvailable, search_id: searchId} = data;
    const isExtraLoadingAvailable = !isLast;

    return {providers, isExtraLoadingAvailable, isThirdpartySearchingAvailable, searchId};
};

export const requestProvider = ({npi, profileId, useRibbonHealth, searchId}) => async dispatch => {
    const {data: provider, isSuccess} = await apiServices.getProvider({npi, profileId, useRibbonHealth, searchId});
    const {offices, ...restProvider} = provider;
    const updatedOffices = offices.map(getFormattedWorkingHours).sort(sortOfficeByName);
    const updatedProvider = {...restProvider, offices: updatedOffices, isFullDataLoaded: true};

    dispatch(setProvider({[npi]: updatedProvider}));

    return {provider: updatedProvider, isSuccess};
};

export const requestProviderUpdating = (npi, formData) => async dispatch => {
    const {data, isSuccess} = await apiServices.updateProvider({npi, provider: formData});

    if (!isSuccess) {
        return {isSuccess, submissionErrors: data.messages};
    }

    dispatch(setProvider({[npi]: data}));

    return {isSuccess};
};

export const requestNetworks = query => async () => {
    const {data} = await apiServices.getNetworks({query});
    const {networks = []} = data;

    return {networks};
};

export const requestSpecialtyGroups = query => async () => {
    const {data} = await apiServices.getSpecialtyGroups({query});
    const {specialty_groups: specialtyGroups = []} = data;

    return {specialtyGroups};
};

export const requestSpecialties = query => async () => {
    const {data} = await apiServices.getSpecialties({query});
    const {specialties = []} = data;

    return {specialties};
};

export const requestServiceDetailList = npi => async dispatch => {
    const {data} = await apiServices.getServiceDetailList({npi});
    const {service_details: serviceDetails} = data;

    dispatch(setServiceDetails({[npi]: [...serviceDetails]}));

    return {serviceDetails};
};

export const requestServiceDetailAdding = (npi, savedFields) => async (dispatch, getState) => {
    const state = getState();
    const serviceDetails = getServiceDetails(state);
    const foundServiceDetails = serviceDetails[npi] ?? [];

    const {data: serviceDetail} = await apiServices.addServiceDetail({npi, savedFields});
    const updatedServiceDetails = [...foundServiceDetails, serviceDetail];

    dispatch(setServiceDetails({[npi]: updatedServiceDetails}));

    return {serviceDetail};
};

export const requestServiceDetailUpdating = (npi, serviceDetailId, savedFields) => async (dispatch, getState) => {
    const state = getState();
    const serviceDetails = getServiceDetails(state);
    const foundServiceDetails = serviceDetails[npi] ?? [];

    const {data: serviceDetail} = await apiServices.updateServiceDetail({npi, serviceDetailId, savedFields});
    const updatedServiceDetails = foundServiceDetails.map(({id, ...restData}) => equal(id, serviceDetailId) ? serviceDetail : {...restData, id});

    dispatch(setServiceDetails({[npi]: updatedServiceDetails}));

    return {serviceDetail};
};

export const requestServiceDetailDeleting = (npi, serviceDetailId) => async (dispatch, getState) => {
    const state = getState();
    const serviceDetails = getServiceDetails(state);
    const foundServiceDetails = serviceDetails[npi] ?? [];

    const {isSuccess} = await apiServices.deleteServiceDetail({npi, serviceDetailId});
    const filteredServiceDetails = foundServiceDetails.filter(negateFunc(getEqual(serviceDetailId, 'id')));

    dispatch(setServiceDetails({[npi]: filteredServiceDetails}));

    return {isSuccess};
};

export const requestNotGoodOptionList = npi => async dispatch => {
    const {data} = await apiServices.getNotGoodOptionsList({npi});
    const {not_good_options: notGoodOptions = []} = data ?? {};

    dispatch(setNotGoodOptions({[npi]: notGoodOptions}));

    return {notGoodOptions};
};

export const requestNotGoodOptionAdding = (npi, savedFields) => async (dispatch, getState) => {
    const state = getState();
    const notGoodOptions = getNotGoodOptions(state);
    const foundNotGoodOptions = notGoodOptions[npi] ?? [];

    const {data: notGoodOption} = await apiServices.addNotGoodOption({npi, savedFields});
    const updatedNotGoodOptions = [...foundNotGoodOptions, notGoodOption];

    dispatch(setNotGoodOptions({[npi]: updatedNotGoodOptions}));

    return {notGoodOption};
};

export const requestNotGoodOptionUpdating = (npi, notGoodOptionId, savedFields) => async (dispatch, getState) => {
    const state = getState();
    const notGoodOptions = getNotGoodOptions(state);
    const foundNotGoodOptions = notGoodOptions[npi] ?? [];

    const {data: notGoodOption} = await apiServices.updateNotGoodOption({npi, notGoodOptionId, savedFields});
    const updatedNotGoodOptions = foundNotGoodOptions.map(({id, ...restData}) => equal(id, notGoodOptionId) ? notGoodOption : {...restData, id});

    dispatch(setNotGoodOptions({[npi]: updatedNotGoodOptions}));

    return {notGoodOption};
};

export const requestNotGoodOptionDeleting = (npi, notGoodOptionId) => async (dispatch, getState) => {
    const state = getState();
    const notGoodOptions = getNotGoodOptions(state);
    const foundNotGoodOptions = notGoodOptions[npi] ?? [];

    const {isSuccess} = await apiServices.deleteNotGoodOption({npi, notGoodOptionId});
    const filteredNotGoodOptions = foundNotGoodOptions.filter(negateFunc(getEqual(notGoodOptionId, 'id')));

    dispatch(setNotGoodOptions({[npi]: filteredNotGoodOptions}));

    return {isSuccess};
};

export const requestTimesRecommended = (npi, page) => async () => {
    const {data, isSuccess} = await apiServices.getTimesRecommended({npi, page});

    if (!isSuccess) {
        return false;
    }

    const {records, page_count: pageCount} = data;

    return {records, pageCount};
};

export const requestSearchContextList = params => async () => {
    const {data, isSuccess} = await apiServices.getSearchContextList(params);

    if (!isSuccess) {
        return false;
    }

    const {search_contexts: searchContexts} = data;

    return {searchContexts};
};

export const requestExpandedSearchContext = searchId => async () => {
    const {data, isSuccess} = await apiServices.getSearchContext({searchId});

    if (!isSuccess) {
        return false;
    }

    const {providers} = data;

    return {providers};
};

export const requestProviderRecordToTicketAdding = ({journeyId, npi}) => async () => {
    await apiServices.addRecordToTicket({journeyId, npi});
};
