import {
    SET_PROVIDER,
    SET_PROVIDER_LIST,
    SET_ACTIVE_NPI,
    SET_SERVICE_DETAILS,
    SET_NOT_GOOD_OPTIONS,
    SET_ACTIVE_OFFICE
} from '../actions/actionTypes';

export const initialState = {
    activeNpi: null,
    activeOffice: 0,
    providerList: {},
    serviceDetails: {},
    notGoodOptions: {}
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_PROVIDER_LIST: {
            const {providerList} = payload;

            return {...state, providerList: {...state.providerList, ...providerList}};
        }

        case SET_PROVIDER: {
            const {provider} = payload;

            return {...state, providerList: {...state.providerList, ...provider}};
        }

        case SET_SERVICE_DETAILS: {
            const {serviceDetails} = payload;

            return {...state, serviceDetails: {...state.serviceDetails, ...serviceDetails}};
        }

        case SET_NOT_GOOD_OPTIONS: {
            const {notGoodOptions} = payload;

            return {...state, notGoodOptions: {...state.notGoodOptions, ...notGoodOptions}};
        }

        case SET_ACTIVE_NPI: {
            const {activeNpi} = payload;

            return {...state, activeNpi};
        }

        case SET_ACTIVE_OFFICE: {
            const {activeOffice} = payload;

            return {...state, activeOffice};
        }

        default: {
            return state;
        }
    }
};
