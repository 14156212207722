import {enUS} from 'date-fns/locale';

export const ROOT_ELEMENT = document.getElementById('crm_v2');

export const HTTP_CODE_UNAUTHORIZED = 401;
export const SERVER_ERROR = 'SERVER_ERROR';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const CANCEL_ERROR = 'CANCEL_ERROR';

export const {HJ_ENV: ENV, API_HOST, GOOGLE_MAPS_BASE_URL, GOOGLE_MAPS_API_URL, GOOGLE_API_KEY, SENTRY_DSN} = window.__RUNTIME_CONFIG__ || {};

const MULTI_ENV_DEV_PREFIX = window.location.hostname.match(/hj[0-9]{2}-dev/)?.[0] || 'hj01-dev';
export const API_BASE_URL = `https://${API_HOST || `crm-bff.${MULTI_ENV_DEV_PREFIX}.hjoy.net`}/api/v1`;

const BASE_ROUTE = '/v2';
const PHA_BASE_ROUTE = `${BASE_ROUTE}/pha`;

export const ROUTES = {
    decisionCenter: `${BASE_ROUTE}/decision_center`,
    providerRecords: `${BASE_ROUTE}/provider_records`,
    PHAProfile: `${PHA_BASE_ROUTE}/profile`
};

export const DECISION_CENTER_CONTEXT_NAME = 'dcV2';

// TODO: Should be renamed to "JOURNEY_TYPES" (Slava, 14.04.2022)
export const DECISION_TYPES = {
    provider: 'provider',
    genericProvider: 'generic_provider',
    appointment: 'appointment'
};

export const DECISION_KINDS = {
    provider: 'provider',
    appointment: 'appointment',
    walletCard: 'wallet_card'
};

export const FORMS = {
    providerRecord: 'provider_record',
    providerSearch: 'provider_search',
    recordToTicket: 'provider_to_dc',
    providerDecision: 'provider_decision',
    appointmentDecision: 'appointment_decision',
    providerDetails: 'provider_details'
};

export const DECISION_FORMS_BY_TYPE = {
    [DECISION_TYPES.provider]: FORMS.providerDecision,
    [DECISION_TYPES.genericProvider]: FORMS.providerDecision,
    [DECISION_TYPES.appointment]: FORMS.appointmentDecision
};

export const CARD_TEMPLATE_CATEGORIES = {
    greetings: 'Greetings',
    whyRecommend: 'Why we recommend',
    providerHighlight: 'Public Provider Highlights',
    insuranceNotes: 'Insurance notes',
    procedureNotes: 'Procedure notes',
    providerNotes: 'Provider notes'
};

export const PROVIDER_TYPES = {
    individual: 'individual',
    organization: 'organization'
};

export const DECISION_DEFAULT_CARD_TITLES = {
    [DECISION_TYPES.provider]: 'Find Care',
    [DECISION_TYPES.genericProvider]: 'Find Care',
    [DECISION_TYPES.appointment]: 'Appointment'
};

export const DECISION_CARD_KIND_BY_TYPE = {
    [DECISION_TYPES.provider]: 'provider',
    [DECISION_TYPES.genericProvider]: 'provider',
    [DECISION_TYPES.appointment]: 'appointment'
};

export const TICKET_SOURCES = {
    app: 'app',
    crm: 'crm'
};

export const VENDOR_TYPES = {
    ideon: 'ideon',
    ribbon: 'ribbon'
};

export const DECISION_OFFICE_FIELD_NAMES = {
    [DECISION_TYPES.provider]: 'office_ids',
    [DECISION_TYPES.genericProvider]: 'office_ids',
    [DECISION_TYPES.appointment]: 'office_id'
};

export const IMAGES_URL = 'https://cdn.healthjoy.com/public/application';

export const AUTOCOMPLETE_MIN_LENGTH = 3;

export const INT_REGEXP = /^[0-9]*$/;
/* eslint-disable-next-line */
export const PHONE_NUMBER_REGEXP = /\([0-9]{3}\)\s[0-9]{3}[-\s\.][0-9]{4}$/;
export const EMAIL_REGEXP = /^[a-zA-Z0-9_!.#$%&'*+\-/=?^`{|}~]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
/* eslint-disable-next-line */
export const URL_REGEXP = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
export const TIME_FORMAT_24_HOURS_REGEXP = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;
export const TIME_FORMAT_12_HOURS_REGEXP = /(((0[1-9])|(1[0-2])):([0-5])(0|5)\s(A|P|a|p)(M|m))/;

export const TIME_PICKER_ENTIRE_TIME_FORMAT_REGEXP = /^(\d{2}):(\d{2}):(\d{2})$/;
export const TIME_PICKER_FORM_TIME_FORMAT_REGEXP = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

export const DEFAULT_PHONE_PLACEHOLDER = '(XXX) XXX-XXXX';

export const INTERNAL_NPI_LENGTH = 15;

export const LANGUAGE_LOCALES = {
    en: 'en',
    es: 'es'
};

export const LANGUAGE_NAMES = {
    en: 'English',
    es: 'Spanish'
};

export const LANGUAGES = [
    {value: LANGUAGE_LOCALES.en, label: LANGUAGE_NAMES.en},
    {value: LANGUAGE_LOCALES.es, label: LANGUAGE_NAMES.es}
];

export const NETWORK_TYPES = {
    active: 'active',
    expired: 'expired'
};

export const NETWORK_COLORS = {
    grey: '#f7f7f7'
};

export const NETWORK_BACKGROUND_COLORS = {
    [NETWORK_TYPES.active]: '#2bbf5e',
    [NETWORK_TYPES.expired]: '#ff0000'
};

export const ACCEPTABLE_IMAGE_FORMATS = ['.jpg', '.jpeg', '.png'];
export const MAX_AVATAR_IMAGE_SIZE = 20;

export const TIMEZONE_DATABASE_NAMES = {
    arizonaState: 'America/Phoenix',
    atlantic: 'America/St_Thomas',
    aleutian: 'America/Adak',
    alaska: 'America/Nome',
    eastern: 'America/New_York',
    central: 'America/North_Dakota/Beulah',
    mountain: 'America/Denver',
    pacific: 'America/Los_Angeles',
    hawaii: 'Pacific/Honolulu',
    americanSamoa: 'Pacific/Samoa',
    chamorro: 'Pacific/Guam'
};

export const TIMEZONE_LOCALES = {
    enUS
};

export const WALLET_CARD_IDS = {
    financialAccounts: 'financial_accounts',
    HJEAP: 'hj_eap',
    enrollment: 'enrollment',
    COVID19: 'covid-19',
    behavioralHealth: 'behavioral-health'
};

export const WALLET_CARD_CATEGORIES = {
    [WALLET_CARD_IDS.HJEAP]: 'eap',
    [WALLET_CARD_IDS.financialAccounts]: 'finance',
    [WALLET_CARD_IDS.enrollment]: 'open_enrollment',
    [WALLET_CARD_IDS.behavioralHealth]: 'behavioral',
    [WALLET_CARD_IDS.COVID19]: 'covid'
};

export const NOTIFICATION_POSITION = {
    topRight: 'top-right',
    topCenter: 'top-center',
    topLeft: 'top-left',
    bottomRight: 'bottom-right',
    bottomCenter: 'bottom-center',
    bottomLeft: 'bottom-left'
};

export const NOTIFICATION_TYPES = {
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    success: 'success'
};

export const NOTIFICATION_CLOSING_DELAY = 2500;

export const DECISION_REASON_FIELD_MAX_LENGTH = 2048;

export const DEFAULT_PROVIDER_OFFICE_INDEX = 0;

export const DRAFT_ITEMS = {
    draftTicketList: 'draftTicketList',
    draftProviderList: 'draftProviderList',
    draftServiceDetailList: 'draftServiceDetailList'
};

export const LOCAL_STORAGE_ITEMS = {
    draftData: 'draftData'
};

export const GLOBAL_ERRORS_MESSAGES = {
    main_user_id: 'Decision is no longer active or member is no longer active - decision cannot be altered'
};
