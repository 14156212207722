import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import {requestSpecialtyGroups} from '../../../actions/providers';
import {getEqual, promisifyAsyncFunction} from '../../../utils';
import {AUTOCOMPLETE_MIN_LENGTH} from '../../../constants';

const SpecialtyGroupAutocomplete = props => {
    const {disabled, onChange} = props;
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [foundSpecialtyGroups, setFoundSpecialtyGroups] = useState([]);

    const loadOptions = promisifyAsyncFunction(async query => {
        if (query.length < AUTOCOMPLETE_MIN_LENGTH) {
            return;
        }

        const {specialtyGroups} = await dispatch(requestSpecialtyGroups(query));
        setFoundSpecialtyGroups(specialtyGroups);

        return specialtyGroups.map(({name}) => ({label: name, value: name}));
    });

    const onChangeSpecialtyGroup = value => {
        const foundSpecialtyGroup = foundSpecialtyGroups.find(getEqual(value, 'name')) ?? {};

        setValue(value);
        onChange(foundSpecialtyGroup);
    };

    const asyncAutocompleteProps = {
        ...props,
        value,
        disabled,
        isCreatable: false,
        isClearable: true,
        loadOptions,
        placeholder: 'Please, enter at least 3 characters',
        onChange: onChangeSpecialtyGroup
    };

    return <AsyncAutocomplete {...asyncAutocompleteProps}/>;
};

SpecialtyGroupAutocomplete.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

SpecialtyGroupAutocomplete.defaultProps = {
    disabled: false
};

export {SpecialtyGroupAutocomplete as TestableSpecialtyGroupAutocomplete};
export default React.memo(SpecialtyGroupAutocomplete);
