import {configureStore} from '@reduxjs/toolkit';
import {routerMiddleware} from 'connected-react-router';
import {actionNavigationControllerMiddleware} from '@frontend/ui-kit/Components/NavigationController';
import history from './history';
import rootReducer from './reducers';

export default configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
        routerMiddleware(history),
        actionNavigationControllerMiddleware
    ]),
    devTools: process.env.NODE_ENV !== 'production'
});
