import React, {useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import {Form, FormSpy} from '../../Components/shared/FormComponents';
import GeneralInfo from '../../Components/DecisionCenter/GeneralInfo';
import DecisionTabs from '../../Components/DecisionCenter/DecisionTabs';
import ProviderFormLayout from '../../Components/DecisionCenter/ProviderFormLayout';
import AppointmentFormLayout from '../../Components/DecisionCenter/AppointmentFormLayout';
import WalletCardFormLayout from '../../Components/DecisionCenter/WalletCardFormLayout';
import withPopup from '../withPopup';
import {requestCardDecisionUpdating, setActiveDecisionKind, setActiveDecisionTab} from '../../actions/decisionCenter';
import {setActiveNpi} from '../../actions/providers';
import {setFormData, notify, saveDraftData, getDraftData, deleteDraftData} from '../../actions/shared';
import {getJourneyAddress, getActiveDecisionKind, getActiveDecisionTab} from '../../selectors/decisionCenter';
import {getFormData} from '../../selectors/shared';
import useFormattedDecisionCenterFormData from '../../hooks/useFormattedDecisionCenterFormData';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import {compose, getEqual, equal, isEmpty, isObject, getErrorFieldNames} from '../../utils';
import {DECISION_FORMS_BY_TYPE, DECISION_TYPES, DECISION_KINDS, NOTIFICATION_CLOSING_DELAY, DRAFT_ITEMS} from '../../constants';

const POPUP_ID = 'withDecisionCenterFormPopup';
const FORM_SPY_SUBSCRIPTION = {dirty: true, values: true};

const FORM_LAYOUT_BY_KIND = {
    [DECISION_KINDS.provider]: ProviderFormLayout,
    [DECISION_KINDS.appointment]: AppointmentFormLayout,
    [DECISION_KINDS.walletCard]: WalletCardFormLayout
};

const withDecisionCenterForm = (validate = () => {}, isTabsValidation = false) => Component => {
    const WithDecisionCenterForm = props => {
        const {decisionType, journeyId, openPopup, closePopup} = props;
        const dispatch = useDispatch();
        const saveNoteRef = useRef(null);
        const journeyAddress = useSelector(getJourneyAddress);
        const formType = DECISION_FORMS_BY_TYPE[decisionType];
        const formData = useSelector(getFormData(formType));
        const activeDecisionTab = useSelector(getActiveDecisionTab);
        const formattedFormData = useFormattedDecisionCenterFormData(formData);
        const isDecisionTabsDisplayed = [DECISION_TYPES.provider, DECISION_TYPES.genericProvider].some(getEqual(decisionType));

        const activeDecisionKind = useSelector(getActiveDecisionKind);
        const FormLayoutComponent = FORM_LAYOUT_BY_KIND[activeDecisionKind];

        let submit;
        const onSubmit = async () => {
            const updatedFormData = {...formattedFormData, initial_location: journeyAddress, save_note: saveNoteRef.current};
            const {isSuccess, submissionErrors} = await dispatch(requestCardDecisionUpdating(journeyId, updatedFormData));

            if (!isSuccess) {
                const {decisions = []} = submissionErrors;
                const decisionIndex = decisions.findIndex(isObject);

                if (!equal(decisionIndex, -1) && !equal(decisionIndex, activeDecisionTab)) {
                    await dispatch(setActiveDecisionTab(decisionIndex));
                }

                return submissionErrors;
            }
            const draftTicket = await dispatch(getDraftData(DRAFT_ITEMS.draftTicketList, journeyId));

            if (draftTicket) {
                const {decisions} = draftTicket;
                const decisionsNpis = !isEmpty(decisions) ? decisions.map(({details}) => details.npi) : [];

                decisionsNpis.map(npi => dispatch(deleteDraftData(DRAFT_ITEMS.draftProviderList, npi)));
                dispatch(deleteDraftData(DRAFT_ITEMS.draftTicketList, journeyId));
            }

            dispatch(notify('Decision was saved!', {autoCloseDelay: NOTIFICATION_CLOSING_DELAY}));
        };

        const openConfirmPopup = () => {
            const onSave = saveNote => async () => {
                saveNoteRef.current = saveNote;
                await submit();
                closePopup();
            };

            const actionBar = (
                <React.Fragment>
                    <Button type={BUTTON_TYPES.primary} onClick={onSave(true)}>Yes</Button>
                    <Button type={BUTTON_TYPES.secondary} onClick={onSave(false)}>No</Button>

                    <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                </React.Fragment>
            );

            const popupProps = {title: 'Do you want to save note in OTRS?', actionBar, children: null};
            const children = <PopupContent {...popupProps}/>;

            openPopup({modalType: 'simple', children});
        };

        const switchToDecisionTabWithErrors = errors => {
            const {decisions = []} = errors ?? {};
            const foundTabsWithErrors = decisions.reduce((acc, decision, index) => !isEmpty(getErrorFieldNames(decision)) ? [...acc, index] : acc, []);

            if (isEmpty(foundTabsWithErrors)) {
                return;
            }

            const [tabIndex] = foundTabsWithErrors;
            const {kind, details: {npi}} = formData.decisions[tabIndex];

            if (npi) {
                dispatch(setActiveNpi(npi));
            }

            dispatch(setActiveDecisionKind(kind));
            dispatch(setActiveDecisionTab(tabIndex));
        };

        const confirmDecision = async (isValid, errors) => {
            if (isTabsValidation && !isValid) {
                await switchToDecisionTabWithErrors(errors);
                // FYI: After tab was switched, we need to highlight fields with errors, therefore have to call "submit" (Slava, 15.12.2021)
                submit();

                return;
            }

            openConfirmPopup();
        };

        const onChangeFormSpy = useCallback(({dirty: isDirty, values}) => {
            if (isDirty) {
                dispatch(saveDraftData(DRAFT_ITEMS.draftTicketList, journeyId, values));
            }
            dispatch(setFormData({[formType]: values}));
        }, []);

        const onDebouncedFormChange = useDebouncedCallback(onChangeFormSpy);

        return (
            <Form initialValues={formData} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit, values, valid: isValid, errors}) => {
                    submit = handleSubmit;
                    const {decisions = []} = values;
                    const isDecisionExists = !isEmpty(decisions);

                    const onConfirmDecision = () => confirmDecision(isValid, errors);

                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <FormSpy subscription={FORM_SPY_SUBSCRIPTION} onChange={onDebouncedFormChange}/>
                            <Row>
                                <Column sm={12}>
                                    <GeneralInfo className='mb-10' decisionType={decisionType}/>
                                </Column>

                                {!isDecisionExists && (
                                    <Column sm={6}>
                                        <Alert type={ALERT_TYPES.warning} description='Please add a Provider to create a Decision'/>
                                    </Column>
                                )}

                                {isDecisionTabsDisplayed && (
                                    <Column sm={12}>
                                        <DecisionTabs decisions={decisions}/>
                                    </Column>
                                )}

                                {isDecisionExists && activeDecisionKind && (
                                    <React.Fragment>
                                        <FormLayoutComponent decisions={decisions}>
                                            <Component decisionType={decisionType}/>
                                        </FormLayoutComponent>

                                        <Separator type='solid'/>
                                    </React.Fragment>
                                )}

                                <Column sm={12}>
                                    <Row end='sm'>
                                        <Column sm={3}>
                                            <Button type={BUTTON_TYPES.primary} disabled={!isDecisionExists} onClick={onConfirmDecision}>Confirm Decision</Button>
                                        </Column>
                                    </Row>
                                </Column>
                            </Row>
                        </form>
                    );
                }}
            </Form>
        );
    };

    WithDecisionCenterForm.propTypes = {
        decisionType: PropTypes.string,
        journeyId: PropTypes.string,
        openPopup: PropTypes.func,
        closePopup: PropTypes.func
    };

    return WithDecisionCenterForm;
};

export {withDecisionCenterForm as testableWithDecisionCenterForm};
export default ({validate, isTabsValidation}) => compose(
    withPopup(POPUP_ID),
    withDecisionCenterForm(validate, isTabsValidation)
);
