import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import DateTimePicker from '@frontend/ui-kit/Components/DateTimePicker';
import {Field} from '../../shared/FormComponents';
import {normalizeNumber} from '../../../utils';
import {TIMEZONE_OPTIONS} from '../../../options';

const AppointmentDate = ({className, prefixName}) => {
    return (
        <div className={classnames('appointment-date', className)}>
            <Heading className='mb-5' type={HEADING_TYPES['4']}>Appointment date</Heading>

            <Row>
                <Column sm={6}>
                    <Field name={`${prefixName}.local_dt`}>
                        {props => (
                            <DateTimePicker {...props}
                                label='Date and time'
                                minDate={new Date()}
                                isClearable
                                timeIntervals={5}
                                showTimeSelect
                                dateFormat='MM/dd/yyyy hh:mm aa'/>
                        )}
                    </Field>
                </Column>
                <Column sm={4}>
                    <Field name={`${prefixName}.timezone`}>
                        {props => <Select {...props} label='Time zone' options={TIMEZONE_OPTIONS}/>}
                    </Field>
                </Column>
                <Column sm={2}>
                    <Field name={`${prefixName}.duration`} parse={normalizeNumber}>
                        {props => <Input {...props} label='Duration' type='number'/>}
                    </Field>
                </Column>
            </Row>
        </div>
    );
};

AppointmentDate.propTypes = {
    className: PropTypes.string,
    prefixName: PropTypes.string.isRequired
};

AppointmentDate.defaultProps = {
    className: ''
};

export {AppointmentDate as TestableAppointmentDate};
export default AppointmentDate;
