import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import BasicLayout from '../../Components/shared/BasicLayout';
import RecordToTicket from '../../Components/Provider/RecordToTicket';
import ServiceDetails from '../../Components/Provider/ServiceDetails';
import NotGoodOptions from '../../Components/Provider/NotGoodOptions';
import ProviderForm from '../../Components/Provider/ProviderForm';
import {setActiveNpi} from '../../actions/providers';

const ProviderRecords = () => {
    const {npi} = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (npi) {
            dispatch(setActiveNpi(Number(npi)));
            setIsLoaded(true);
        }
    }, [npi, dispatch]);

    return isLoaded && (
        <BasicLayout>
            <Heading className='mb-15' type={HEADING_TYPES['1']}>
                Provider record
            </Heading>
            <Row>
                <Column sm={5}>
                    <RecordToTicket className='mb-15' npi={npi}/>
                </Column>
            </Row>
            <Row>
                <Column sm={5}>
                    <Row>
                        <Column xs={12} className='mb-10'>
                            <NotGoodOptions/>
                        </Column>
                        <Column xs={12}>
                            <ServiceDetails/>
                        </Column>
                    </Row>
                </Column>
                <Column sm={7} className='mb-15'>
                    <ProviderForm isProviderDisableFieldsDisplayed/>
                </Column>
            </Row>
        </BasicLayout>
    );
};

export {ProviderRecords as TestableProviderRecords};
export default ProviderRecords;
