export const AUTH_ENDPOINT = '/auth';

export const JOURNEYS_ENDPOINT = '/journeys';

export const PROVIDER_BASKET_ENDPOINT = '/provider_basket';

export const TIMES_RECOMMENDED_ENDPOINT = '/times_recommended';

export const RECORD_TO_DC_ENDPOINT = '/record_to_decision';

export const CARD_TEMPLATES_ENDPOINT = '/get_card_templates';

export const CARD_DECISIONS_ENDPOINT = '/get_card_decisions';

export const CARD_DECISION_ENDPOINT = '/set_card_decision';

export const CARD_PREVIEW_ENDPOINT = '/get_card_preview_data';

export const CUSTOMERS_ENDPOINT = '/customers';

export const USERS_ENDPOINT = '/users';

export const CPT_GROUPS_ENDPOINT = '/cpt_group';

export const PHA_USER_ENDPOINT = '/current_user';

export const AVATAR_ENDPOINT = '/upload_avatar';

export const WALLET_CARDS_ENDPOINT = '/wallet_cards';

export const DECISION_FORM_TEMPLATES_ENDPOINT = '/decision-form-templates';
