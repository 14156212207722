import {parse, stringify, parseUrl, stringifyUrl} from 'query-string';
import {startOfWeek, addDays, parseISO, compareDesc, getTime} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';
import {v4 as uuidv4} from 'uuid';
import {diff as getDiff} from 'deep-object-diff';
import dotObject from 'dot-object';
import scrollIntoView from 'scroll-into-view';
import {
    EMAIL_REGEXP,
    INT_REGEXP,
    URL_REGEXP,
    TIME_FORMAT_24_HOURS_REGEXP,
    TIMEZONE_DATABASE_NAMES,
    TIMEZONE_LOCALES
} from './constants';

export const equal = (val, other) => val === other;

export const pipe = (...funcs) => {
    const _pipe = (prevFunc, currFunc) => (...arg) => currFunc(prevFunc(...arg));

    return funcs.reduce(_pipe);
};

export const compose = (...funcs) => pipe(...funcs.reverse());

export const getMatches = (rules, value = '') => {
    return Object
        .entries(rules)
        .reduce((acc, [key, pattern]) => ({...acc, [key]: value.match(pattern)}), {});
};

export const pass = val => val;

export const negate = val => !val;

export const negateFunc = func => pipe(func, negate);

export const isObject = val => !equal(val, null) && equal(typeof val, 'object');

export const isPrimitive = val => val !== Object(val);

export const isBoolean = val => equal(typeof val, 'boolean');

export const isFunction = val => equal(typeof val, 'function');

export const isFinite = val => Number.isFinite(val);

export const isFile = val => val instanceof File;

export const isEmpty = obj => {
    if (isPrimitive(obj)) {
        return !obj;
    }
    if (Array.isArray(obj)) {
        return !obj.length;
    }

    return isEmpty(Object.keys(obj));
};

export const partial = (func, ...params) => (...args) => func(...params, ...args);

export const getItemKeyValue = key => obj => obj[key];

export const getEqual = (value, key) => pipe(key ? getItemKeyValue(key) : pass, partial(equal, value));

export const groupBy = (arr, key, initial = {}) => arr.reduce((acc, item) => {
    const {[key]: groupKey} = item;
    const groupedItems = acc[groupKey] || [];

    return {...acc, [groupKey]: [...groupedItems, item]};
}, initial);

export const omit = (obj, keys) => Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));

export const splitIntoParts = (arr = [], partsCount = 2) => {
    const separator = Math.ceil(arr.length / partsCount);

    return Array(partsCount).fill(null).reduce((acc, item, index) => {
        const nextIndex = index + 1;

        return [...acc, arr.slice(separator * index, separator * nextIndex)];
    }, []);
};

export const promisifyAsyncFunction = (func, resolve = res => res, reject = () => {}) => (...params) => func(...params).then(resolve).catch(reject);

export const decorateWith = (...decorators) => (target, name, descriptor) => {
    const decorate = compose(...decorators);
    const originalFunc = descriptor.value;
    const funcName = originalFunc.name;
    const boundFunc = originalFunc.bind(target);
    boundFunc.__funcName__ = name || funcName;

    return {...descriptor, value: decorate(boundFunc)};
};

export const getRegisteredFieldsValues = (registeredFields, values, nestingLevel = 0) => {
    return Object
        .keys(registeredFields)
        .map(fieldName => fieldName.split('.')[nestingLevel])
        .reduce((acc, fieldName) => ({...acc, [fieldName]: values[fieldName]}), {});
};

export const getErrorFieldNames = (errors = {}) => {
    const getFieldNames = (errors, name = '') => {
        if (isPrimitive(errors)) {
            return name;
        }

        if (Array.isArray(errors)) {
            return errors.map((item, index) => item && getFieldNames(item, `${name}[${index}]`));
        }

        return Object.entries(errors).map(([key, value]) => value && getFieldNames(value, name ? `${name}.${key}` : key));
    };

    return getFieldNames(errors).flat(Infinity).filter(Boolean);
};

export const getNormalizedErrorMessages = values => {
    if (!Array.isArray(values)) {
        return {};
    }

    const updateValuesToRow = values.reduce((acc, value) => {
        const {loc: field, msg} = value;
        const [, ...fieldPath] = field;

        return {...acc, [fieldPath.join('.')]: msg};
    }, {});

    return dotObject.object(updateValuesToRow);
};

export const getUpdatedValues = (values, initialValues = {}) => {
    const getFormattedDiff = (diff, value) => {
        if (Array.isArray(value) || isPrimitive(value)) {
            return value;
        }

        return Object.entries(diff).reduce((acc, [key, val]) => ({...acc, [key]: getFormattedDiff(val, value[key])}), {});
    };

    return getFormattedDiff(getDiff(initialValues, values), values);
};

export const getUtcToZonedTime = (date, timeZone = TIMEZONE_DATABASE_NAMES.central) => utcToZonedTime(date, timeZone);

export const formatDate = (date, dateFormat, options) => date && format(date, dateFormat, {timeZone: TIMEZONE_DATABASE_NAMES.central, locale: TIMEZONE_LOCALES.enUS, ...options});

export const parseDateISO = (date, options = {}) => parseISO(date, options);

export const getDateTime = date => getTime(date);

export const getFileFormat = name => name && name.split('.').reverse()?.[0];

export const getFileUrl = file => file && (file.dataUrl || file);

export const toCapitalize = str => str && (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());

export const trimStart = str => str && str.replace(/^ +/g, '');

/* eslint-disable-next-line */
export const normalizeBoolean = value => ({'true': true, 'false': false, 'null': null}[value]);

export const normalizeNumber = value => value === '' ? null : Number(value);

export const normalizeList = value => (value || '').split('\n').map(line => line.replace(/✓/g, '').replace(/^ /g, '')).join('\n');

export const formatList = value => (value || '').split('\n').map(line => line ? `✓ ${line}` : '').join('\n');

export const formatPhone = phone => phone && `+1 ${phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}`;

export const formatMoney = (money, separator = ',', currency = '$') => {
    const formattedMoney = money.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${separator}`);

    return `${currency}${formattedMoney}`;
};

export const bytesToMegabytes = bytes => bytes / 1024 / 1000;

export const minToSec = min => min * 60;
export const secToMin = sec => sec / 60;

export const scrollToComponent = (component, time = 500) => scrollIntoView(component, {time});

export const validateInt = (value, range = {}) => {
    const {from = -Infinity, to = Infinity} = range;
    const isInRange = value >= from && value <= to;

    const intValidationMessage = !INT_REGEXP.test(value) && 'Enter a numeric value';
    const rangeValidationMessage = !isInRange && `Enter value between ${from} and ${to}`;

    return intValidationMessage || rangeValidationMessage || undefined;
};
export const validateEmail = email => !EMAIL_REGEXP.test(email) ? 'Please enter a valid email' : undefined;
export const validateRequired = value => isEmpty(value) ? 'Required' : undefined;
export const validatePasswordConfirm = (passwordConfirm, password) => passwordConfirm !== password ? ' ' : undefined;
export const validateLength = length => value => value && `${value}`.length !== length ? `Must be ${length} characters` : undefined;
export const validateMaxLength = (value, maxLength) => {
    return value && value.length > maxLength ? `Must be ${maxLength} characters or less` : undefined;
};
export const validateURL = url => !URL_REGEXP.test(url) ? 'Please enter a valid url' : undefined;
export const validate24HoursTimeFormat = time => !TIME_FORMAT_24_HOURS_REGEXP.test(time) ? 'Please enter a valid 12-hour time format, time step is 5 mins' : undefined;
export const validateFileSize = (value, maxSize) => {
    const errorMessage = `Your uploaded file is too big. Maximum file size is: ${maxSize}Mb`;
    const files = [].concat(value).filter(item => isFile(item));
    const isInvalidSize = files.some(({size}) => bytesToMegabytes(size) > maxSize);

    return isInvalidSize ? errorMessage : undefined;
};

export const toPercent = (value, limit = 0) => (parseFloat(value, 10) * 100).toFixed(limit);

export const getDelimitedNum = (num = 0) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getIncreasedByCoefficient = (value, coefficient) => value * coefficient;

export const trimByMaxLength = (str, maxLength, endMark = '...', separator = ' ') => {
    if (str?.length > maxLength) {
        const trimmedValue = str.substr(0, str.lastIndexOf(separator, maxLength - endMark.length));

        return `${trimmedValue}${endMark}`;
    }

    return str;
};

export const isEven = number => number % 2 === 0;

export const getUnfilledArray = (length = 0) => Array(length).fill(undefined);

export const getTextFromHtml = (value, shouldBeTrimmed = false) => {
    const element = document.createElement('div');
    element.innerHTML = value;

    return shouldBeTrimmed ? element.innerText.trim() : element.innerText;
};

export const wrapTextIntoHtmlTag = (text, tagName = 'p') => `<${tagName}>${text}</${tagName}>`;

export const normalizeText = value => (value || '').trim().replace(/↵/g, '\\n');

export const normalizeMarkupEditor = value => getTextFromHtml(value, true).length > 0 ? value : '';

export const delay = (func, ms = 0) => setTimeout(func, ms);

export const moveArrayItem = (arr, oldIndex, newIndex) => {
    const updatedArray = [...arr];
    const [item] = updatedArray.splice(oldIndex, 1);
    updatedArray.splice(newIndex, 0, item);

    return updatedArray;
};

export const debounce = (func, delay = 100) => {
    let timeout = null;

    return (...args) => {
        clearTimeout(timeout);

        return new Promise(resolve => {
            const next = () => resolve(func(...args));

            timeout = setTimeout(next, delay);
        });
    };
};

export const parseQuery = query => parse(query, {arrayFormat: 'comma'});

export const stringifyQueryParams = params => stringify(params, {arrayFormat: 'comma'});

export const parseURL = url => parseUrl(url);

export const stringifyURL = (data, options = {}) => stringifyUrl({...data}, options);

export const truncateString = (string, length) => (
    string.length <= length ? string : `${string.slice(0, length)}...`
);

export const getDayNames = (dayPattern = 'EEE', weekStartsOn = 1) => {
    const firstDayOfWeek = startOfWeek(new Date(), {weekStartsOn});

    return [...Array(7)].map((day, index) => format(addDays(firstDayOfWeek, index), dayPattern));
};

export const compareDatesByDesc = (firstDate, secondDate) => compareDesc(firstDate, secondDate);

export const generateUniqueId = () => uuidv4();

export const getScript = (filepath, callback, timeout = null, async = true, defer = true) => (
    new Promise((resolve, reject) => {
        const CALLBACK_NAME = 'getScriptCallback';
        const isCallback = !!callback;
        const formattedFilepath = isCallback ? `${filepath}&callback=${CALLBACK_NAME}` : filepath;

        const alreadyLoadedScript = document.querySelector(`script[src='${formattedFilepath}']`);

        if (alreadyLoadedScript) {
            resolve(alreadyLoadedScript);
            return;
        }

        if (isCallback) {
            window[CALLBACK_NAME] = callback;
        }

        let loadedTimeout = null;

        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = async;
        script.defer = defer;

        const onloadHandler = (event, isAbort) => {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = null;
                script.onreadystatechange = null;
                script = undefined;

                if (loadedTimeout) {
                    clearTimeout(loadedTimeout);
                    loadedTimeout = null;
                }

                if (isAbort) {
                    return reject(new Error('Script loading failed'));
                }

                resolve(event);
            }
        };

        const head = document.getElementsByTagName('head')[0];
        if (head) {
            script.onload = onloadHandler;
            script.onreadystatechange = onloadHandler;
            script.src = formattedFilepath;

            head.appendChild(script);

            if (timeout) {
                loadedTimeout = setTimeout(() => {
                    reject(new Error(`Script loading timed out (${timeout / 1000}s)`));
                }, timeout);
            }
        } else {
            reject(new Error('Could not create <script/> element'));
        }
    })
);

export const areFieldsEmpty = (obj, ignoredFields = []) => {
    const isFieldEmpty = key => {
        const value = obj[key];

        if (ignoredFields.includes(key)) {
            return true;
        }

        if (Array.isArray(value)) {
            return isEmpty(value);
        }

        if (value && typeof value === 'object') {
            return areFieldsEmpty(value, ignoredFields);
        }

        return typeof value === 'boolean' ? false : !value;
    };

    return !obj || Object.keys(obj).every(isFieldEmpty);
};

export const getUniqueListBy = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];

export const to24HoursFormat = currTime => {
    const [time, modifier] = currTime.split(' ');
    const [hours, minutes] = time.split(':');

    const updatedHours = equal(hours, '12') ? '00' : hours;
    const transformedHours = equal(modifier, 'PM') ? parseInt(updatedHours, 10) + 12 : updatedHours;

    return `${transformedHours}:${minutes}:00`;
};

export const to12HoursFormat = currTime => {
    const [hours, minutes] = currTime.split(':');
    const modifier = hours < 12 ? 'AM' : 'PM';
    const updatedHours = hours % 12 || 12;
    const transformedHours = updatedHours < 10 ? `0${updatedHours}` : updatedHours;

    return `${transformedHours}:${minutes} ${modifier}`;
};

export const getSplittedText = value => value.split('\n');
