import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import BasicLayout from '../../Components/shared/BasicLayout';
import withCRMSidebar from '../withCRMSidebar';
import {requestDecisionCenterBasicData, requestCustomerBasicData} from '../../actions/decisionCenter';
import {compose} from '../../utils';

const withDecisionCenterData = Component => {
    const WithDecisionCenterData = props => {
        const {journeyId, customerId} = props;
        const dispatch = useDispatch();
        const [isLoaded, setIsLoaded] = useState(false);
        const [decisionType, setDecisionType] = useState();

        useEffect(() => {
            (async () => {
                const {decisionType} = await dispatch(requestDecisionCenterBasicData(journeyId));

                if (customerId) {
                    await dispatch(requestCustomerBasicData(customerId));
                }

                setDecisionType(decisionType);
                setIsLoaded(true);
            })();
        }, [journeyId, customerId, dispatch]);

        const componentProps = {...props, journeyId, decisionType};

        return (
            <BasicLayout>
                {isLoaded && <Component {...componentProps}/>}
            </BasicLayout>
        );
    };

    WithDecisionCenterData.propTypes = {
        journeyId: PropTypes.string,
        customerId: PropTypes.string
    };

    return WithDecisionCenterData;
};

export {withDecisionCenterData as testableWithDecisionCenterData};
export default compose(
    withCRMSidebar,
    withDecisionCenterData
);
