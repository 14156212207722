import React from 'react';
import {ToastContainer, Slide} from 'react-toastify';
import {NOTIFICATION_POSITION} from '../../../constants';
import styles from './index.module.scss';
import 'react-toastify/dist/ReactToastify.min.css';

const Notification = props => {
    const notificationProps = {
        position: NOTIFICATION_POSITION.topRight,
        transition: Slide,
        hideProgressBar: true,
        autoClose: false,
        draggable: false,
        closeButton: false,
        className: styles.notificationContainer,
        bodyClassName: styles.notificationBody,
        ...props
    };

    return <ToastContainer {...notificationProps}/>;
};

export default Notification;
