import React from 'react';
import PropTypes from 'prop-types';
import Column from '@frontend/ui-kit/Components/Column';

const WalletCardFormLayout = ({children}) => {
    return (
        <Column sm={12}>
            {children}
        </Column>
    );
};

WalletCardFormLayout.propTypes = {
    children: PropTypes.node
};

export {WalletCardFormLayout as TestableWalletCardFormLayout};
export default WalletCardFormLayout;
