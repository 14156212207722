import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {getCustomerAccessToken, getProductHostname} from '../../../selectors/decisionCenter';
import {DECISION_CENTER_CONTEXT_NAME} from '../../../constants';
import {stringifyURL} from '../../../utils';
import styles from './index.module.scss';

const WalletCardPreview = ({category, planId}) => {
    const [walletCardIframeUrl, setWalletCardIframeUrl] = useState(null);
    const customerAccessToken = useSelector(getCustomerAccessToken);
    const productHostname = useSelector(getProductHostname);
    const walletCardUrl = `https://${productHostname}/v2/wallet_card`;

    useEffect(() => {
        const url = stringifyURL({
            url: walletCardUrl,
            query: {
                dashboard_plan_id: planId,
                category,
                access_token: customerAccessToken,
                previewContext: DECISION_CENTER_CONTEXT_NAME
            }
        }, {skipNull: true});

        setWalletCardIframeUrl(url);
    }, [planId, category, walletCardUrl, customerAccessToken]);

    return (
        <div className={styles['wallet-card-preview']}>
            <iframe referrerPolicy='strict-origin-when-cross-origin' data-testid='preview-iframe' className={styles['wallet-card-preview__iframe']} title='wallet-card-preview' src={walletCardIframeUrl}/>
        </div>
    );
};

WalletCardPreview.propTypes = {
    category: PropTypes.string,
    planId: PropTypes.number
};

export {WalletCardPreview as TestableWalletCardPreview};
export default React.memo(WalletCardPreview);
