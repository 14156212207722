import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Link from '@frontend/ui-kit/Components/Link';
import Table from '@frontend/ui-kit/Components/Table';
import {requestServiceDetailList, requestTimesRecommended} from '../../../actions/providers';
import {getValueCell} from '../../../helpers';
import {isEmpty} from '../../../utils';
import styles from './index.module.scss';

const getServiceDetails = (({text}) => <li>{text}</li>);

const getLinkCell = item => <Link href={item.value} target='_blank'>View</Link>;

const tableColumns = [
    {Header: 'Member’s  Name', width: 200, accessor: 'member_name'},
    {Header: 'Intake', accessor: 'ticket_subject', width: 340, Cell: getValueCell},
    {Header: 'Ticket link', accessor: 'ticket_link', width: 170, Cell: getLinkCell},
    {Header: 'DC link', accessor: 'decision_url', width: 170, Cell: getLinkCell}
];

const RecommendationsInfoPopup = props => {
    const {npi, formattedlastTimeRecommended, formatedUpdatedEarliestAvailability, timesRecommended, wasRecommendedToUser} = props;
    const [serviceDetails, setServiceDetails] = useState([]);
    const [tablePage, setTablePage] = useState(1);
    const [tableData, setTableData] = useState({records: [], pageCount: 0});
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const {serviceDetails} = await dispatch(requestServiceDetailList(npi));

            setServiceDetails(serviceDetails);
        })();
    }, [npi, dispatch]);

    useEffect(() => {
        (async () => {
            const {records, pageCount} = await dispatch(requestTimesRecommended(npi, tablePage));

            setTableData({records, pageCount});
        })();
    }, [npi, tablePage, dispatch]);

    // FYI: Pagination start from "0", but for loading data we start from "1" (Slava, 10.07.2021)
    const onPageChange = page => setTablePage(page + 1);

    const tableProps = {
        data: tableData.records,
        columns: tableColumns,
        unit: 'records',
        isFilterable: false,
        isSortable: false,
        pages: tableData.pageCount,
        pageSize: 15,
        onPageChange
    };

    return (
        <div className={classnames(styles['recommendations-info-popup'])}>
            <Row className='mb-5' between='sm' middle='sm'>
                <Column>
                    <Heading type={HEADING_TYPES['3']}>Recommended records</Heading>
                </Column>
            </Row>

            <Row className='mb-15'>
                <Column>
                    <Heading className='mb-5' type={HEADING_TYPES['5']}>Service details:</Heading>

                    {!isEmpty(serviceDetails) && (
                        <ul className='ml-10'>{serviceDetails.map(getServiceDetails)}</ul>
                    )}

                    {isEmpty(serviceDetails) && (
                        <Text>No service details</Text>
                    )}
                </Column>
            </Row>

            <Row className='mb-10 mt-15'>
                <Column>
                    <Text>Last Recommended:</Text>
                </Column>
                &nbsp;
                <Column>
                    <Text type={TEXT_TYPES.bodyBold}>{formattedlastTimeRecommended}</Text>
                </Column>
            </Row>
            <Row className='mb-10 mt-15'>
                <Column>
                    <Text>Recommended to this user:</Text>
                </Column>
                &nbsp;
                <Column>
                    <Text type={TEXT_TYPES.bodyBold}>
                        {wasRecommendedToUser
                            ? <span className={styles['recommendations-info-popup__was-recommended']}>Yes</span>
                            : <span className={styles['recommendations-info-popup__was-not-recommended']}>No</span>}
                    </Text>
                </Column>
            </Row>
            <Row className='mb-10'>
                <Column>
                    <Text>Times Recommended:</Text>
                </Column>
                &nbsp;
                <Column>
                    <Text type={TEXT_TYPES.bodyBold}>{timesRecommended}</Text>
                </Column>
            </Row>
            <Row className='mb-20'>
                <Column>
                    <Text>Earliest Availability:</Text>
                </Column>
                &nbsp;
                <Column>
                    <Text type={TEXT_TYPES.bodyBold}>{formatedUpdatedEarliestAvailability}</Text>
                </Column>
            </Row>

            {npi && <Table {...tableProps}/>}
        </div>
    );
};

RecommendationsInfoPopup.propTypes = {
    npi: PropTypes.number,
    onClose: PropTypes.func,
    formattedlastTimeRecommended: PropTypes.string,
    formatedEarliestAvailabilityUpdated: PropTypes.string,
    formatedUpdatedEarliestAvailability: PropTypes.string,
    timesRecommended: PropTypes.number,
    wasRecommendedToUser: PropTypes.bool
};

export {RecommendationsInfoPopup as TestableRecommendationsInfoPopup};
export default React.memo(RecommendationsInfoPopup);
