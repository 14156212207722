import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import BasicLayout from '../../../Components/shared/BasicLayout';
import ProfileForm from '../../../Components/PHA/ProfileForm';
import {requestPHAUser} from '../../../actions/pha';

const Profile = () => {
    const [profile, setProfile] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const {phaUser} = await dispatch(requestPHAUser());

            setProfile(phaUser);
        })();
    }, [dispatch]);

    return (
        <BasicLayout>
            <Heading className='mb-15' type={HEADING_TYPES['1']}>
                Profile
            </Heading>

            <ProfileForm profile={profile}/>
        </BasicLayout>
    );
};

export {Profile as TestableProfile};
export default Profile;
