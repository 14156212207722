import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import CollapseInitiator from '../../shared/CollapseInitiator';
import ProviderDecisionForm from '../ProviderDecisionForm';
import ProvidersSearch from '../ProvidersSearch';
import SearchContexts from '../SearchContexts';
import withLoadingMaps from '../../../HOC/withLoadingMaps';
import {compose} from '../../../utils';

const ProviderDecision = ({decisionType, journeyId}) => {
    return (
        <React.Fragment>
            <ProvidersSearch className='mb-15' decisionType={decisionType}/>

            <Collapse className='mb-15'
                initiator={<CollapseInitiator title='Search contexts'/>}
                content={<SearchContexts/>}
                hasCollapseIcon/>

            <ProviderDecisionForm decisionType={decisionType} journeyId={journeyId}/>
        </React.Fragment>
    );
};

ProviderDecision.propTypes = {
    decisionType: PropTypes.string,
    journeyId: PropTypes.string
};

export {ProviderDecision as TestableProviderDecision};
export default compose(
    React.memo,
    withLoadingMaps
)(ProviderDecision);
