import React from 'react';
import PropTypes from 'prop-types';
import {Form as SimpleForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import scrollToErrorDecorator from './scrollToErrorDecorator';

const Form = ({children, mutators, decorators = [], ...restProps}) => {
    const formProps = {
        mutators: {...mutators, ...arrayMutators},
        decorators: [...decorators, scrollToErrorDecorator],
        ...restProps
    };

    return <SimpleForm {...formProps}>{children}</SimpleForm>;
};

Form.propTypes = {
    children: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}),
    decorators: PropTypes.arrayOf(PropTypes.func),
    mutators: PropTypes.shape({}),
    validate: PropTypes.func,
    onSubmit: PropTypes.func.isRequired
};

export default Form;
