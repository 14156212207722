import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';

const CollapseInitiator = ({title}) => <Heading type={HEADING_TYPES['4']}>{title}</Heading>;

CollapseInitiator.propTypes = {
    title: PropTypes.string
};

export {CollapseInitiator as TestableCollapseInitiator};
export default CollapseInitiator;
