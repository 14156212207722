import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import styles from './index.module.scss';

const BasicLayout = props => {
    const {children} = props;

    return (
        <div className={styles['basic-layout']}>
            <Loader/>

            {children}
        </div>
    );
};

BasicLayout.propTypes = {
    children: PropTypes.node
};

export {BasicLayout as TestableBasicLayout};
export default BasicLayout;
