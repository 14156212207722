import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import Text from '@frontend/ui-kit/Components/Text';
import Button from '@frontend/ui-kit/Components/Button';
import {Field, FieldArray} from '../../shared/FormComponents';
import useForm from '../../../hooks/useForm';
import {getDayNames, to24HoursFormat, to12HoursFormat} from '../../../utils';
import {TIME_FORMAT_24_HOURS_REGEXP, TIME_FORMAT_12_HOURS_REGEXP} from '../../../constants';

const WORKDAYS_INDEXES = [0, 1, 2, 3, 4];
const WORKING_HOUR_MASK = '99:99 aa';
const daysOfWeek = getDayNames('EEEE');
const formatHour = value => TIME_FORMAT_24_HOURS_REGEXP.test(value) ? to12HoursFormat(value) : value;
const parseHour = value => TIME_FORMAT_12_HOURS_REGEXP.test(value) ? to24HoursFormat(value.toUpperCase()) : value;

const WorkingDays = ({prefixName, disabled}) => {
    const form = useForm();

    const onCopyData = () => {
        const startHour = form.getFieldState('week.start_hour').value || null;
        const endHour = form.getFieldState('week.end_hour').value || null;

        form.batch(() => {
            WORKDAYS_INDEXES.forEach(index => {
                form.change(`${prefixName}.working_hours[${index}].start_hour`, startHour);
                form.change(`${prefixName}.working_hours[${index}].end_hour`, endHour);
            });
        });
    };

    return (
        <div className='working-days'>
            <Heading className='mb-15' type={HEADING_TYPES['5']}>Hours & Availability</Heading>

            <Row middle='sm'>
                <Column sm={2}>
                    <Text>Set hours for Mon-Fri</Text>
                </Column>
                <Column sm={3}>
                    <Field name='week.start_hour' format={formatHour} parse={parseHour}>
                        {props => <Input {...props} mask={WORKING_HOUR_MASK} maskChar='-' disabled={disabled} placeholder='--:-- AM'/>}
                    </Field>
                </Column>
                <div>-</div>
                <Column sm={3}>
                    <Field name='week.end_hour' format={formatHour} parse={parseHour}>
                        {props => <Input {...props} mask={WORKING_HOUR_MASK} maskChar='-' disabled={disabled} placeholder='--:-- PM'/>}
                    </Field>
                </Column>
                <Column sm={2}>
                    <Button onClick={onCopyData} disabled={disabled}>Apply</Button>
                </Column>
            </Row>

            <Separator type='solid'/>

            <FieldArray name={`${prefixName}.working_hours`}>
                {({fields}) => fields.map((field, index) => {
                    const nameOfDay = daysOfWeek[index];

                    return (
                        <div key={field} className='mb-5'>
                            <Heading className='mb-5' type={HEADING_TYPES['6']}>{nameOfDay}</Heading>

                            <Row start='sm' middle='sm'>
                                <Column sm={3}>
                                    <Field name={`${field}.start_hour`} format={formatHour} parse={parseHour}>
                                        {props => <Input {...props} mask={WORKING_HOUR_MASK} maskChar='-' disabled={disabled} placeholder='--:-- AM'/>}
                                    </Field>
                                </Column>
                                <div>:</div>
                                <Column sm={3}>
                                    <Field name={`${field}.end_hour`} format={formatHour} parse={parseHour}>
                                        {props => <Input {...props} mask={WORKING_HOUR_MASK} maskChar='-' disabled={disabled} placeholder='--:-- PM'/>}
                                    </Field>
                                </Column>
                            </Row>
                        </div>
                    );
                })}
            </FieldArray>
        </div>
    );
};

WorkingDays.propTypes = {
    prefixName: PropTypes.string,
    disabled: PropTypes.bool
};

WorkingDays.defaultProps = {
    disabled: false
};

export {WorkingDays as TestableWorkingDays};
export default React.memo(WorkingDays);
