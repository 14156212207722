import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Column from '@frontend/ui-kit/Components/Column';
import ProviderForm from '../../Provider/ProviderForm';
import ServiceDetails from '../../Provider/ServiceDetails';
import styles from './index.module.scss';

const ProviderFormLayout = ({decisions, children}) => {
    return (
        <React.Fragment>
            <Column sm={5}>
                {children}

                <ServiceDetails/>
            </Column>
            <Column sm={7}>
                <ProviderForm isCustomerAddressDisplayed
                    className={classnames({[styles.providerFormLayoutExtraPadding]: decisions.length > 1})}/>
            </Column>
        </React.Fragment>
    );
};

ProviderFormLayout.propTypes = {
    children: PropTypes.node,
    decisions: PropTypes.arrayOf(PropTypes.shape())
};

export {ProviderFormLayout as TestableProviderFormLayout};
export default ProviderFormLayout;
