export const getProviderList = state => state.providers.providerList;

export const getProviderByNpi = npi => state => {
    const {providers: {providerList}} = state;

    return providerList[npi] ?? {};
};

export const getServiceDetails = state => state.providers.serviceDetails;

export const getNotGoodOptions = state => state.providers.notGoodOptions;

export const getActiveNpi = state => state.providers.activeNpi;

export const getActiveOffice = state => state.providers.activeOffice;
