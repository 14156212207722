import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from '@frontend/ui-kit/Components/Select';
import useForm from '../../../hooks/useForm';
import useField from '../../../hooks/useField';
import {truncateString, equal, getTextFromHtml, wrapTextIntoHtmlTag} from '../../../utils';

const TemplatePicker = props => {
    const form = useForm();
    const {field, templates, className, placeholder, labelLength, isAddable, isMarkup} = props;
    const {input: {value: fieldValue}} = useField(field);

    const getTemplateOption = ({title, content}) => ({
        value: content,
        label: truncateString(title || content, labelLength)
    });

    const templatesOptions = templates.map(getTemplateOption);

    const onChangeTemplate = value => {
        // FYI: When Field uses the MarkupEditor we have to
        // - wrap string to "<p>" because MarkupEditor does the same with each new line
        // - split string by "\n" otherwise new lines will be ignored (Slava, 27.07.2022)
        const wrappedValue = isMarkup ? value.split('\n').map(text => wrapTextIntoHtmlTag(text)).join('') : value;
        const isValueExists = !equal(getTextFromHtml(fieldValue, true).length, 0);
        const updatedValue = isAddable && isValueExists ? [fieldValue, wrappedValue].join('\n') : wrappedValue;

        form.change(field, updatedValue);
    };

    return (
        <Select className={classnames('template-picker', className)}
            options={templatesOptions}
            placeholder={placeholder}
            onChange={onChangeTemplate}/>
    );
};

TemplatePicker.propTypes = {
    field: PropTypes.string.isRequired,
    templates: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        category: PropTypes.string
    })),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isAddable: PropTypes.bool,
    isMarkup: PropTypes.bool,
    labelLength: PropTypes.number
};

TemplatePicker.defaultProps = {
    templates: [],
    className: '',
    placeholder: 'Select a template',
    labelLength: 75,
    isAddable: true,
    isMarkup: false
};

export {TemplatePicker as TestableTemplatePicker};
export default React.memo(TemplatePicker);
