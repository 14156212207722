import React from 'react';
import PropTypes from 'prop-types';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Layout from '../Layout';
import styles from './index.module.scss';

const GlobalError = ({error}) => (
    <Layout>
        <div className={styles.globalError}>
            <Alert type={ALERT_TYPES.danger} title='You have encountered an error'/>
            <div className={styles.globalErrorBody}>
                <Text className={styles.globalErrorMessage} type={TEXT_TYPES.bodyBold}>
                    {error.message}
                </Text>
            </div>
        </div>
    </Layout>
);

GlobalError.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string.isRequired
    })
};

export {GlobalError as TestableGlobalError};
export default GlobalError;
