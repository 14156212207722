import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button from '@frontend/ui-kit/Components/Button';
import ProviderDecision from '../../Components/DecisionCenter/ProviderDecision';
import AppointmentDecision from '../../Components/DecisionCenter/AppointmentDecision';
import IntakeInfo from '../../Components/DecisionCenter/IntakeInfo';
import LanguageSelector from '../../Components/shared/LanguageSelector';
import CardPreview from '../../Components/DecisionCenter/CardPreview';
import withDecisionCenterData from '../../HOC/withDecisionCenterData';
import {requestCardTemplates, requestSteerageTypes, requestCustomSteerageTypes, requestCustomerWalletCards, requestCustomerAccessToken, setJourneyLocale, requestKisxData} from '../../actions/decisionCenter';
import {getJourneyLocale, getSelectedCustomerProfile, getCustomerAccessToken} from '../../selectors/decisionCenter';
import {compose, getEqual} from '../../utils';
import {DECISION_TYPES} from '../../constants';

const DECISION_CENTER_BY_TYPE = {
    [DECISION_TYPES.provider]: ProviderDecision,
    [DECISION_TYPES.genericProvider]: ProviderDecision,
    [DECISION_TYPES.appointment]: AppointmentDecision
};

const DecisionCenter = props => {
    const {journeyId, decisionType} = props;
    const dispatch = useDispatch();
    const {main_user_id: mainCustomerId, user_id: userId} = useSelector(getSelectedCustomerProfile);
    const customerAccessToken = useSelector(getCustomerAccessToken);
    const journeyLocale = useSelector(getJourneyLocale);
    const onJourneyLocaleChange = value => dispatch(setJourneyLocale(value));
    const isProviderDecisionType = [DECISION_TYPES.provider, DECISION_TYPES.genericProvider].some(getEqual(decisionType));

    useEffect(() => {
        if (isProviderDecisionType) {
            dispatch(requestKisxData());
            dispatch(requestSteerageTypes());
        }
    }, [isProviderDecisionType, dispatch]);

    useEffect(() => {
        if (isProviderDecisionType && mainCustomerId) {
            dispatch(requestCustomerWalletCards(mainCustomerId));
        }
    }, [mainCustomerId, isProviderDecisionType, dispatch]);

    useEffect(() => {
        const customerId = mainCustomerId || userId;

        if (isProviderDecisionType && customerId) {
            dispatch(requestCustomSteerageTypes(customerId));
        }
    }, [userId, mainCustomerId, isProviderDecisionType, dispatch]);

    useEffect(() => {
        if (!customerAccessToken && mainCustomerId) {
            dispatch(requestCustomerAccessToken(mainCustomerId));
        }
    }, [mainCustomerId, customerAccessToken, dispatch]);

    useEffect(() => {
        dispatch(requestCardTemplates(journeyId, journeyLocale));
    }, [journeyId, journeyLocale, dispatch]);

    const DecisionCenterComponent = DECISION_CENTER_BY_TYPE[decisionType];
    const onClearStorage = () => localStorage.clear();

    return (
        <React.Fragment>
            <Row middle='sm' between='sm'>
                <Column>
                    <Heading className='mb-15' type={HEADING_TYPES['1']}>
                        Decision center
                    </Heading>
                </Column>
                <Column sm={6}>
                    <Row middle='sm' between='sm'>
                        <Column sm={4}>
                            <LanguageSelector locale={journeyLocale} onChange={onJourneyLocaleChange}/>
                        </Column>
                        <Column sm={2}>
                            <CardPreview journeyId={journeyId} decisionType={decisionType}/>
                        </Column>
                        <Column sm={4}>
                            <Button onClick={onClearStorage}>Clear localStorage</Button>
                        </Column>
                    </Row>
                </Column>
            </Row>

            <IntakeInfo className='mb-15' decisionType={decisionType}/>

            <DecisionCenterComponent journeyId={journeyId} decisionType={decisionType}/>
        </React.Fragment>
    );
};

DecisionCenter.propTypes = {
    journeyId: PropTypes.string,
    decisionType: PropTypes.string
};

export {DecisionCenter as TestableDecisionCenter};
export default compose(
    React.memo,
    withDecisionCenterData
)(DecisionCenter);
