import {
    RECEIVE_DECISION_CENTER_BASIC_DATA,
    SET_CUSTOMER_RELATED_PROFILES,
    SET_CUSTOMER_NETWORK_NAME,
    SET_CUSTOMER_ACCESS_TOKEN,
    SET_JOURNEY_INITIAL_ADDRESS,
    SET_JOURNEY_ADDRESS,
    SET_ACTIVE_DECISION_TAB,
    SET_JOURNEY_LOCALE,
    SET_CARD_TEMPLATES,
    SET_STEERAGE_TYPES,
    SET_CUSTOM_STEERAGE_TYPES,
    SET_SELECTED_CUSTOMER_PROFILE,
    SET_ACTIVE_DECISION_KIND,
    SET_PRODUCT_HOSTNAME,
    SET_SEARCH_CONTEXT_ID,
    SET_PROVIDER_SEARCH_EXTRA_PARAMS,
    SET_WALLET_CARDS,
    SET_KISX_DATA
} from '../actions/actionTypes';
import {LANGUAGE_LOCALES} from '../constants';

export const initialState = {
    activeDecisionTab: 0,
    activeDecisionKind: null,
    intakeInfo: {
        office_id: null,
        office_ids: [],
        OTRSTicketLink: null,
        locale: null,
        additional_info: null,
        suggest_alternative: null,
        search_id: null,
        questions: [],
        npi: null,
        reason_for_visit: null,
        appointment_preferences: null,
        other_appointment_preferences: null,
        selected_days: null,
        first_availability: null,
        parent_dc_link: null
    },
    possibleRewardsPrograms: [],
    journeyLocale: LANGUAGE_LOCALES.en,
    journeyInitialAddress: '',
    journeyAddress: '',
    cardTemplates: {},
    steerageTypes: [],
    customSteerageTypes: [],
    selectedCustomerId: null,
    selectedCustomerProfile: {},
    customerRelatedProfiles: [],
    walletCards: [],
    customerAccessToken: null,
    customerNetworkName: null,
    productHostname: null,
    searchContextId: null,
    providerSearchExtraParams: {}
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_DECISION_CENTER_BASIC_DATA: {
            const {intakeInfo, ...restBasicData} = payload;

            return {
                ...state,
                ...restBasicData,
                intakeInfo: {...state.intakeInfo, ...intakeInfo}
            };
        }

        case SET_JOURNEY_INITIAL_ADDRESS: {
            const {journeyInitialAddress} = payload;

            return {...state, journeyInitialAddress};
        }

        case SET_JOURNEY_ADDRESS: {
            const {journeyAddress} = payload;

            return {...state, journeyAddress};
        }

        case SET_CARD_TEMPLATES: {
            const {cardTemplates} = payload;

            return {...state, cardTemplates};
        }

        case SET_STEERAGE_TYPES: {
            const {steerageTypes} = payload;

            return {...state, steerageTypes};
        }

        case SET_CUSTOM_STEERAGE_TYPES: {
            const {customSteerageTypes} = payload;

            return {...state, customSteerageTypes};
        }

        case SET_ACTIVE_DECISION_TAB: {
            const {activeDecisionTab} = payload;

            return {...state, activeDecisionTab};
        }

        case SET_CUSTOMER_RELATED_PROFILES: {
            const {customerRelatedProfiles} = payload;

            return {...state, customerRelatedProfiles};
        }

        case SET_JOURNEY_LOCALE: {
            const {journeyLocale} = payload;

            return {...state, journeyLocale};
        }

        case SET_SELECTED_CUSTOMER_PROFILE: {
            const {selectedCustomerProfile} = payload;

            return {...state, selectedCustomerProfile};
        }

        case SET_CUSTOMER_ACCESS_TOKEN: {
            const {customerAccessToken} = payload;

            return {...state, customerAccessToken};
        }

        case SET_CUSTOMER_NETWORK_NAME: {
            const {customerNetworkName} = payload;

            return {...state, customerNetworkName};
        }

        case SET_WALLET_CARDS: {
            const {walletCards} = payload;

            return {...state, walletCards};
        }

        case SET_ACTIVE_DECISION_KIND: {
            const {activeDecisionKind} = payload;

            return {...state, activeDecisionKind};
        }

        case SET_PRODUCT_HOSTNAME: {
            const {productHostname} = payload;

            return {...state, productHostname};
        }

        case SET_SEARCH_CONTEXT_ID: {
            const {searchContextId} = payload;

            return {...state, searchContextId};
        }

        case SET_PROVIDER_SEARCH_EXTRA_PARAMS: {
            const {providerSearchExtraParams} = payload;

            return {...state, providerSearchExtraParams: {...providerSearchExtraParams}};
        }

        case SET_KISX_DATA: {
            const {kisxData} = payload;

            return {...state, kisxData};
        }

        default: {
            return state;
        }
    }
};
