import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getProviderList} from '../selectors/providers';
import {DECISION_KINDS, WALLET_CARD_CATEGORIES} from '../constants';
import {equal} from '../utils';

const useFormattedDecisionCenterFormData = formData => {
    const providerList = useSelector(getProviderList);
    const {decisions, ...restFormData} = formData;

    const updatedDecisions = useMemo((() => decisions.map(decision => {
        /* eslint-disable-next-line */
        const {cid, kind, details, ...restDecision} = decision; // FYI: We don't need "cid" key on BE side (Slava, 17.04.2022)
        /* eslint-disable-next-line */
        const {npi, type, category, walletCardId, ...restDetails} = details; // FYI: We don't need "walletCardId" key on BE side (Stanislav, 05.07.2022)
        const isWalletCardKind = equal(kind, DECISION_KINDS.walletCard);

        const walletCardDecisionDetails = {
            category: WALLET_CARD_CATEGORIES[category] ?? category
        };
        const providerDecisionDetails = {
            npi,
            type: type ?? providerList[npi]?.type
        };

        return {
            ...restDecision,
            kind,
            details: {
                ...restDetails,
                ...isWalletCardKind && walletCardDecisionDetails,
                ...!isWalletCardKind && providerDecisionDetails
            }
        };
    })), [decisions, providerList]);

    return {...restFormData, decisions: updatedDecisions};
};

export default useFormattedDecisionCenterFormData;
