import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Select from '@frontend/ui-kit/Components/Select';
import useForm from '../../../hooks/useForm';
import {requestSpecialties} from '../../../actions/providers';
import {getItemKeyValue, equal, promisifyAsyncFunction, getEqual} from '../../../utils';
import {AUTOCOMPLETE_MIN_LENGTH} from '../../../constants';

const getSpecialtyOption = ({display_name: label}) => ({label, value: label});

const ProviderSpecialties = ({specialties, isEditable}) => {
    const dispatch = useDispatch();
    const [foundSpecialties, setFoundSpecialties] = useState([]);
    const form = useForm();
    const primarySpecialty = useMemo(() => specialties.find(getItemKeyValue('is_primary')), [specialties]);
    const {display_name: primarySpecialtyName} = primarySpecialty ?? {};

    const primarySpecialtiesOptions = useMemo(() => specialties.map(getSpecialtyOption), [specialties]);

    const loadOptions = promisifyAsyncFunction(async query => {
        if (query.length < AUTOCOMPLETE_MIN_LENGTH) {
            return;
        }

        const {specialties} = await dispatch(requestSpecialties(query));
        setFoundSpecialties(specialties);

        return specialties.map(({display_name: displayName}) => ({label: displayName, value: displayName}));
    });

    const onChangePrimarySpecialty = value => {
        const updatedSpecialties = specialties.map(({display_name: displayName, ...rest}) => {
            return {...rest, display_name: displayName, is_primary: equal(displayName, value)};
        });

        form.change('specialties', updatedSpecialties);
    };

    const onChangeSpecialties = values => {
        const specialtyNames = values ?? [];

        if (primarySpecialtyName && !specialtyNames.includes(primarySpecialtyName)) {
            return false;
        }

        const updatedSpecialties = specialtyNames.map(specialtyName => {
            return [...specialties, ...foundSpecialties].find(getEqual(specialtyName, 'display_name'));
        });

        form.change('specialties', updatedSpecialties);
    };

    return (
        <Row>
            <Column sm={8} className='mb-12'>
                <Select value={primarySpecialtyName}
                    label='Primary specialty'
                    options={primarySpecialtiesOptions}
                    disabled={!isEditable}
                    onChange={onChangePrimarySpecialty}/>
            </Column>

            <Column sm={12} className='mb-12'>
                <AsyncAutocomplete value={specialties.map(getItemKeyValue('display_name'))}
                    label='Specialties'
                    loadOptions={loadOptions}
                    disabled={!isEditable}
                    isMulti
                    isCreatable={false}
                    onChange={onChangeSpecialties}/>
            </Column>
        </Row>
    );
};

ProviderSpecialties.propTypes = {
    specialties: PropTypes.arrayOf(PropTypes.shape({})),
    isEditable: PropTypes.bool
};

export default React.memo(ProviderSpecialties);
