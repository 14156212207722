import {toast} from 'react-toastify';
import Alert from '@frontend/ui-kit/Components/Alert';
import {
    START_LOADING,
    FINISH_LOADING,
    CLOSE_POPUP,
    OPEN_POPUP,
    SHOW_NOTIFICATION,
    REMOVE_NOTIFICATION,
    SET_FORM_DATA
} from './actionTypes';
import {NOTIFICATION_TYPES, LOCAL_STORAGE_ITEMS} from '../constants';
import {generateUniqueId} from '../utils';

export const startLoading = isLoader => ({type: START_LOADING, isLoader});

export const finishLoading = () => ({type: FINISH_LOADING});

export const openPopup = params => ({type: OPEN_POPUP, ...params});

export const closePopup = ({id}) => ({type: CLOSE_POPUP, id});

export const setFormData = formData => ({type: SET_FORM_DATA, formData});

export const showNotification = ({notificationId, notificationType}) => ({type: SHOW_NOTIFICATION, notificationId, notificationType});

export const removeNotification = notificationId => ({type: REMOVE_NOTIFICATION, notificationId});

export const notify = (text, options = {}) => dispatch => {
    const {type: notificationType = NOTIFICATION_TYPES.success, autoCloseDelay, ...restOptions} = options;
    const notificationId = generateUniqueId();
    const onOpen = () => {
        if (options.onOpen) {
            options.onOpen();
        }

        dispatch(showNotification({notificationId, notificationType}));
    };
    const onClose = () => {
        if (options.onClose) {
            options.onClose();
        }

        dispatch(removeNotification(notificationId));
    };

    const updatedOptions = {...restOptions, toastId: notificationId, autoClose: autoCloseDelay, onOpen, onClose};
    /* eslint-disable-next-line */
    toast(({closeToast}) => <Alert type={notificationType} description={text} onClose={closeToast}/>, updatedOptions);

    return {notificationId};
};

export const getDraftData = (draftItem, key) => () => {
    const draftData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.draftData)) || {};
    const draftDataItem = draftData[draftItem] ?? {};

    return draftDataItem[key];
};

export const saveDraftData = (draftItem, key, data) => () => {
    const draftData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.draftData)) || {};
    const draftDataItem = draftData[draftItem] ?? {};

    draftDataItem[key] = data;

    draftData[draftItem] = draftDataItem;

    const formattedDraftData = JSON.stringify(draftData);
    localStorage.setItem(LOCAL_STORAGE_ITEMS.draftData, formattedDraftData);
};

export const deleteDraftData = (draftItem, key) => () => {
    const draftData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.draftData)) || {};
    const draftDataItem = draftData[draftItem] ?? {};

    delete draftDataItem[key];
    draftData[draftItem] = draftDataItem;

    const formattedDraftData = JSON.stringify(draftData);
    localStorage.setItem(LOCAL_STORAGE_ITEMS.draftData, formattedDraftData);
};
