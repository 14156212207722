import {AUTHORIZE} from './actionTypes';

const authorize = authorizationData => ({type: AUTHORIZE, ...authorizationData});

const setAuthorizationData = ({accessToken, refreshToken}) => dispatch => {
    dispatch(authorize({accessToken, refreshToken}));
};

export const handleTokenRefresh = tokenData => dispatch => {
    const {access_token: accessToken, refresh_token: refreshToken} = tokenData;

    dispatch(setAuthorizationData({accessToken, refreshToken}));
};

export const login = () => dispatch => {
    // FYI: Have to get access data from global variable because CRMv.2 is a part of CRM and doesn't have "Sing in" page (Slava, 31.01.2022)
    const {chat} = window.projectConf ?? {};
    const {user} = chat ?? {};
    const {token: accessToken, refresh_token: refreshToken} = user ?? {};

    dispatch(setAuthorizationData({accessToken, refreshToken}));
};

export const signOut = () => async () => {
    // FYI: Have to move User to CRM's "Login" page (Slava, 01.02.2022)
    location.replace('/login');
};
