import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import withPopup from '../../../HOC/withPopup';
import {requestCardPreview} from '../../../actions/decisionCenter';
import {getJourneyLocale, getJourneyAddress} from '../../../selectors/decisionCenter';
import {getFormData} from '../../../selectors/shared';
import useFormattedDecisionCenterFormData from '../../../hooks/useFormattedDecisionCenterFormData';
import {parseAddress} from '../../../helpers';
import {isEmpty, compose} from '../../../utils';
import {DECISION_FORMS_BY_TYPE} from '../../../constants';
import styles from './index.module.scss';

const POPUP_ID = 'cardPreviewPopup';

const CardPreview = props => {
    const {journeyId, decisionType, openPopup} = props;
    const dispatch = useDispatch();
    const formType = DECISION_FORMS_BY_TYPE[decisionType];
    const formData = useSelector(getFormData(formType));
    const formattedFormData = useFormattedDecisionCenterFormData(formData);
    const journeyAddress = useSelector(getJourneyAddress);
    const journeyLocale = useSelector(getJourneyLocale);
    const isCardPreviewAvailable = !isEmpty(formData.decisions);

    const openCardPreviewPopup = previewUrl => {
        const popupContent = <div className={styles.cardPreview}><iframe referrerPolicy='strict-origin-when-cross-origin' className={styles.cardPreviewIframe} title='card-preview' src={previewUrl}/></div>;
        const popupProps = {children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        openPopup({modalType: 'simple', children});
    };

    const onOpenCardPreview = async () => {
        const updatedFormData = {
            ...formattedFormData,
            initial_location: parseAddress(journeyAddress)
        };
        const {previewUrl} = await dispatch(requestCardPreview(journeyId, journeyLocale, updatedFormData));

        openCardPreviewPopup(previewUrl);
    };

    return <Button type={BUTTON_TYPES.primary} disabled={!isCardPreviewAvailable} onClick={onOpenCardPreview}>Preview card</Button>;
};

CardPreview.propTypes = {
    journeyId: PropTypes.string,
    decisionType: PropTypes.string,
    openPopup: PropTypes.func
};

export {CardPreview as TestableCardPreview};
export default compose(
    React.memo,
    withPopup(POPUP_ID)
)(CardPreview);
