import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import {Field} from '../../shared/FormComponents';
import TemplatePicker from '../../shared/TemplatePicker';
import {requestCptGroups} from '../../../actions/decisionCenter';
import {AUTOCOMPLETE_MIN_LENGTH, CARD_TEMPLATE_CATEGORIES} from '../../../constants';
import {promisifyAsyncFunction, normalizeNumber, getEqual} from '../../../utils';

const Procedure = ({prefixName, cardTemplates, className}) => {
    const dispatch = useDispatch();
    const [cptGroups, setCptGroups] = useState([]);

    const loadProcedures = promisifyAsyncFunction(async query => {
        if (query.length < AUTOCOMPLETE_MIN_LENGTH) {
            return;
        }

        const {cptGroups} = await dispatch(requestCptGroups(query));
        setCptGroups(cptGroups);

        return cptGroups.map(({name, cpt_codes: cptCodes}) => {
            return {label: name, value: name, description: cptCodes.join(', ')};
        });
    });

    const onChangeProcedureName = useCallback((name, form) => {
        const {id} = cptGroups.find(getEqual(name, 'name'));

        form.change(`${prefixName}.procedure.cpt_group_id`, id);
    }, [prefixName, cptGroups]);

    return (
        <div className={className}>
            <Heading className='mb-5' type={HEADING_TYPES['4']}>Procedure</Heading>

            <Field name={`${prefixName}.procedure.id`}>{props => <Input {...props} type='hidden'/>}</Field>

            <Row>
                <Column sm={12} className='mb-12'>
                    <Field name={`${prefixName}.procedure.name`} onChange={onChangeProcedureName}>
                        {props => (
                            <AsyncAutocomplete {...props}
                                label='Procedure name'
                                isCreatable={false}
                                loadOptions={loadProcedures}
                                filterOption={option => option}
                                placeholder='Please, enter at least 3 characters'/>
                        )}
                    </Field>

                    <Field name={`${prefixName}.procedure.cpt_group_id`}>{props => <Input {...props} type='hidden'/>}</Field>
                </Column>

                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.procedure.average_price_min`} parse={normalizeNumber}>
                        {props => <Input {...props} label='Average price' type='number'/>}
                    </Field>
                </Column>

                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.procedure.estimated_price_min`} parse={normalizeNumber}>
                        {props => <Input {...props} label='Estimated price' type='number'/>}
                    </Field>
                </Column>

                <Column sm={12}>
                    <Field name={`${prefixName}.procedure.notes`}>
                        {props => <Textarea {...props} label='Procedure notes' maxLength={1024} rows={5}/>}
                    </Field>

                    <TemplatePicker field={`${prefixName}.procedure.notes`}
                        className='mt-12'
                        templates={cardTemplates[CARD_TEMPLATE_CATEGORIES.procedureNotes]}
                        placeholder='Select a "Procedure notes" template'/>
                </Column>
            </Row>
        </div>
    );
};

Procedure.propTypes = {
    prefixName: PropTypes.string.isRequired,
    cardTemplates: PropTypes.shape({}).isRequired,
    className: PropTypes.string
};

Procedure.defaultProps = {
    className: ''
};

export {Procedure as TestableProcedure};
export default Procedure;
