import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import InputFile from '@frontend/ui-kit/Components/InputFile';
import {Form, Field} from '../../shared/FormComponents';
import {requestAvatarUpdating} from '../../../actions/pha';
import {validateFileSize, isFile} from '../../../utils';
import {ACCEPTABLE_IMAGE_FORMATS, MAX_AVATAR_IMAGE_SIZE} from '../../../constants';
import styles from './index.module.scss';

/* istanbul ignore next */
const validate = values => {
    const {avatar} = values;

    return {
        avatar: validateFileSize(avatar, MAX_AVATAR_IMAGE_SIZE)
    };
};

const ProfileForm = ({profile}) => {
    const dispatch = useDispatch();

    const onSubmit = async values => {
        const {avatar} = values;

        await dispatch(requestAvatarUpdating(avatar));
    };

    return (
        <Form initialValues={profile} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, values}) => {
                const {avatar} = values;
                const isAvatarUpdated = isFile(avatar);

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Row className='mb-12'>
                            <Column xs={6} className={styles['profile-form__upload-avatar']}>
                                <Field name='avatar'>{props => (
                                    <InputFile {...props}
                                        label='Upload avatar'
                                        isImage
                                        isAvatar
                                        accept={ACCEPTABLE_IMAGE_FORMATS}
                                        maxSize={MAX_AVATAR_IMAGE_SIZE}/>
                                )}
                                </Field>
                            </Column>
                        </Row>

                        <Row>
                            <Column sm={2} className='mt-12'>
                                <Button type={BUTTON_TYPES.primary} disabled={!isAvatarUpdated} isSubmit>Save</Button>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

ProfileForm.propTypes = {
    profile: PropTypes.shape({
        avatar: PropTypes.oneOfType([PropTypes.string, null])
    })
};

export {ProfileForm as TestableProfileForm};
export default ProfileForm;
