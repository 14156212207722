import apiServices from '../apiServices';

export const requestPHAUser = () => async () => {
    const {data: phaUser} = await apiServices.getPHAUser();

    return {phaUser};
};

export const requestAvatarUpdating = file => async () => {
    const formData = new FormData();
    formData.append('avatar', file);

    const {data} = await apiServices.updateAvatar(formData);
    const {avatar: avatarUrl} = data;

    return {avatarUrl};
};
