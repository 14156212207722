import {AUTHORIZE} from '../actions/actionTypes';

export const initialState = {
    isAuthorized: false,
    accessToken: null,
    refreshToken: null
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case AUTHORIZE: {
            const {accessToken, refreshToken} = payload;

            return {...state, isAuthorized: true, accessToken, refreshToken};
        }

        default: {
            return state;
        }
    }
};
