import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Layout = ({children}) => <div className={styles.errorLayout}>{children}</div>;

Layout.propTypes = {
    children: PropTypes.node
};

export {Layout as TestableLayout};
export default Layout;
