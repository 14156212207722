export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const AUTHORIZE = 'AUTHORIZE';

export const RECEIVE_DECISION_CENTER_BASIC_DATA = 'RECEIVE_DECISION_CENTER_BASIC_DATA';
export const SET_CUSTOMER_RELATED_PROFILES = 'SET_CUSTOMER_RELATED_PROFILES';
export const SET_SELECTED_CUSTOMER_PROFILE = 'SET_SELECTED_CUSTOMER_PROFILE';
export const SET_WALLET_CARDS = 'SET_WALLET_CARDS';
export const SET_CUSTOMER_ACCESS_TOKEN = 'SET_CUSTOMER_ACCESS_TOKEN';
export const SET_CUSTOMER_NETWORK_NAME = 'SET_CUSTOMER_NETWORK_NAME';
export const SET_JOURNEY_INITIAL_ADDRESS = 'SET_JOURNEY_INITIAL_ADDRESS';
export const SET_JOURNEY_ADDRESS = 'SET_JOURNEY_ADDRESS';
export const SET_ACTIVE_DECISION_TAB = 'SET_ACTIVE_DECISION_TAB';
export const SET_ACTIVE_DECISION_KIND = 'SET_ACTIVE_DECISION_KIND';
export const SET_JOURNEY_LOCALE = 'SET_JOURNEY_LOCALE';
export const SET_CARD_TEMPLATES = 'SET_CARD_TEMPLATES';
export const SET_STEERAGE_TYPES = 'SET_STEERAGE_TYPES';
export const SET_CUSTOM_STEERAGE_TYPES = 'SET_CUSTOM_STEERAGE_TYPES';
export const SET_PRODUCT_HOSTNAME = 'SET_PRODUCT_HOSTNAME';
export const SET_SEARCH_CONTEXT_ID = 'SET_SEARCH_CONTEXT_ID';
export const SET_PROVIDER_SEARCH_EXTRA_PARAMS = 'SET_PROVIDER_SEARCH_EXTRA_PARAMS';

export const SET_FORM_DATA = 'SET_FORM_DATA';

export const SET_PROVIDER_LIST = 'SET_PROVIDER_LIST';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_ACTIVE_OFFICE = 'SET_ACTIVE_OFFICE';
export const SET_ACTIVE_NPI = 'SET_ACTIVE_NPI';
export const SET_SERVICE_DETAILS = 'SET_SERVICE_DETAILS';
export const SET_NOT_GOOD_OPTIONS = 'SET_NOT_GOOD_OPTIONS';
export const SET_KISX_DATA = 'SET_KISX_DATA';
