import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import {setActiveDecisionTab, setActiveDecisionKind} from '../../../actions/decisionCenter';
import {setActiveNpi, setActiveOffice} from '../../../actions/providers';
import {getActiveDecisionTab, getWalletCards} from '../../../selectors/decisionCenter';
import {getProviderList} from '../../../selectors/providers';
import {DECISION_KINDS, DEFAULT_PROVIDER_OFFICE_INDEX} from '../../../constants';
import {getWalletCardById} from '../../../helpers';
import {equal} from '../../../utils';
import styles from './index.module.scss';

const DecisionTabs = ({decisions}) => {
    const dispatch = useDispatch();
    const providerList = useSelector(getProviderList);
    const activeDecisionTab = useSelector(getActiveDecisionTab);
    const walletCards = useSelector(getWalletCards);

    const decisionTabs = useMemo(() => decisions.map((decision, index) => {
        const {details, kind} = decision;
        const {npi, category, walletCardId} = details;
        const currentWalletCard = walletCardId ? walletCards.find(getWalletCardById(walletCardId)) : {};
        const title = walletCardId && currentWalletCard?.title ? currentWalletCard.title : category;
        const tabTitle = equal(kind, DECISION_KINDS.walletCard) ? `${title ?? kind} #${index + 1}` : providerList[npi]?.name;

        return `${tabTitle} ${npi ? `(${npi})` : ''}`;
    }), [decisions, providerList, walletCards]);

    const onChangeTab = useCallback(activeTab => {
        const {details, kind} = decisions[activeTab];
        const {npi = null} = details;

        dispatch(setActiveNpi(npi));
        dispatch(setActiveOffice(DEFAULT_PROVIDER_OFFICE_INDEX));
        dispatch(setActiveDecisionTab(activeTab));
        dispatch(setActiveDecisionKind(kind));
    }, [decisions, dispatch]);

    return (
        <Tabs className={classnames(styles['decision-tabs'], 'mb-5')}
            tabs={decisionTabs}
            activeTab={activeDecisionTab}
            onChange={onChangeTab}/>
    );
};

DecisionTabs.propTypes = {
    decisions: PropTypes.arrayOf(PropTypes.shape())
};

export {DecisionTabs as TestableDecisionTabs};
export default React.memo(DecisionTabs);
