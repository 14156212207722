import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Input from '@frontend/ui-kit/Components/Input';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import WalletCardPreview from '../WalletCardPreview';
import {Field} from '../../shared/FormComponents';
import {getIsTPAUser} from '../../../selectors/decisionCenter';
import {getWalletCardById} from '../../../helpers';
import {isEmpty} from '../../../utils';
import {PRE_CERTIFICATION_EVENT_OPTIONS} from '../../../options';

const WalletCardDetails = props => {
    const {prefixName, walletCards, currentWalletCard, walletCardsOptions, isWalletCardPreviewShowed, isPreCertEvent} = props;
    const isTPAUser = useSelector(getIsTPAUser);

    const onChangeWalletCard = (value, form) => {
        const currentWalletCard = walletCards.find(getWalletCardById(value));
        const {planId, category} = currentWalletCard ?? {};

        form.batch(() => {
            form.change(`${prefixName}.details.plan_id`, planId);
            form.change(`${prefixName}.details.category`, category);
        });
    };

    return (
        <div className='wallet-card-details'>
            <Heading className='mb-15' type={HEADING_TYPES['4']}>Decision details</Heading>

            <Field name={`${prefixName}.kind`}>{props => <Input {...props} type='hidden'/>}</Field>

            <Field name={`${prefixName}.details.category`}>{props => <Input {...props} type='hidden'/>}</Field>

            <Row>
                <Column sm={12} className='mb-10'>
                    <Field name={`${prefixName}.is_recommended`}>{props => <Checkbox {...props} caption='Is recommended'/>}</Field>
                </Column>

                {isTPAUser && (
                    <Column sm={4}>
                        <Separator type='solid'/>

                        <Field name={`${prefixName}.details.is_pre_cert_event`} className='mb-10'>
                            {props => <Checkbox {...props} caption='TPA Pre-certification Event'/>}
                        </Field>

                        {isPreCertEvent && (
                            <React.Fragment>
                                <Field name={`${prefixName}.details.pre_cert_provider_changed`} className='mb-10'>
                                    {props => <Checkbox {...props} caption='HCC Recommended Provider'/>}
                                </Field>

                                <Field name={`${prefixName}.details.pre_cert_decision`}>
                                    {props => (
                                        <Select {...props}
                                            label='Pre-Certification Event Decision:'
                                            options={PRE_CERTIFICATION_EVENT_OPTIONS}
                                            placeholder='Select...'/>
                                    )}
                                </Field>
                            </React.Fragment>
                        )}
                    </Column>
                )}
            </Row>

            <Separator type='solid'/>

            <Row>
                <Column sm={4} className='mb-10'>
                    <Field name={`${prefixName}.details.walletCardId`} label='Select a wallet card' onChange={onChangeWalletCard}>
                        {props => <Select {...props} options={walletCardsOptions} maxMenuHeight={200}/>}
                    </Field>
                </Column>
            </Row>

            {!isEmpty(currentWalletCard) && isWalletCardPreviewShowed && (
                <Row>
                    <Column sm={4} className='mb-10'>
                        <WalletCardPreview {...currentWalletCard}/>
                    </Column>
                </Row>
            )}
        </div>
    );
};

WalletCardDetails.propTypes = {
    prefixName: PropTypes.string.isRequired,
    walletCards: PropTypes.arrayOf(PropTypes.shape()),
    cardList: PropTypes.arrayOf(PropTypes.shape()),
    currentWalletCard: PropTypes.shape({
        category: PropTypes.string,
        planId: PropTypes.number
    }),
    walletCardsOptions: PropTypes.arrayOf(PropTypes.shape()),
    isWalletCardPreviewShowed: PropTypes.bool,
    isPreCertEvent: PropTypes.bool
};

WalletCardDetails.defaultProps = {
    isPreCertEvent: false
};

export {WalletCardDetails as TestableWalletCardDetails};
export default React.memo(WalletCardDetails);
