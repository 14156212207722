import React from 'react';
import PropTypes from 'prop-types';
import Decisions from '../Decisions';
import withDecisionCenterForm from '../../../HOC/withDecisionCenterForm';
import {DECISION_REASON_FIELD_MAX_LENGTH} from '../../../constants';
import {compose, validateRequired, validateInt, validateMaxLength, getTextFromHtml} from '../../../utils';

/* istanbul ignore next */
const validateDecision = ({details}) => {
    const {office_id: officeId, why_recommend: whyRecommend, timezone, local_dt: localDT, duration, procedure} = details;
    const {
        average_price_min: averagePriceMin,
        average_price_max: averagePriceMax,
        estimated_price_min: estimatedPriceMin,
        estimated_price_max: estimatedPriceMax
    } = procedure ?? {};
    const isTimezoneValidationAvailable = [localDT, duration].some(Boolean);
    const isLocalDTValidationAvailable = [timezone, duration].some(Boolean);
    const isDurationValidationAvailable = [timezone, localDT].some(Boolean);

    return {
        details: {
            why_recommend: validateRequired(whyRecommend),
            office_id: validateRequired(officeId),
            timezone: isTimezoneValidationAvailable ? validateRequired(timezone) : undefined,
            local_dt: isLocalDTValidationAvailable ? validateRequired(localDT) : undefined,
            duration: isDurationValidationAvailable ? validateRequired(duration) : undefined,
            procedure: {
                average_price_min: averagePriceMin || averagePriceMax ? (validateRequired(averagePriceMin) || validateInt(averagePriceMin)) : undefined,
                average_price_max: averagePriceMax ? (validateInt(averagePriceMax)) : undefined,
                estimated_price_min: estimatedPriceMin || estimatedPriceMax ? (validateInt(estimatedPriceMin) || validateRequired(estimatedPriceMin)) : undefined,
                estimated_price_max: estimatedPriceMax ? (validateRequired(estimatedPriceMax) || validateInt(estimatedPriceMax)) : undefined
            }
        }
    };
};

/* istanbul ignore next */
const validate = values => {
    const {resolution, decisions = []} = values;
    const {reason, title} = resolution ?? {};

    return {
        resolution: {
            reason: validateMaxLength(getTextFromHtml(reason, true), DECISION_REASON_FIELD_MAX_LENGTH) || validateRequired(reason),
            title: validateRequired(title)
        },
        decisions: decisions.map(validateDecision)
    };
};

const AppointmentDecisionForm = ({decisionType}) => {
    return <Decisions decisionType={decisionType}/>;
};

AppointmentDecisionForm.propTypes = {
    decisionType: PropTypes.string
};

export {AppointmentDecisionForm as TestableAppointmentDecisionForm};
export default compose(
    React.memo,
    withDecisionCenterForm({validate})
)(AppointmentDecisionForm);
