import {
    START_LOADING,
    FINISH_LOADING,
    OPEN_POPUP,
    CLOSE_POPUP,
    SHOW_NOTIFICATION,
    REMOVE_NOTIFICATION,
    SET_FORM_DATA
} from '../actions/actionTypes';

const initialState = {
    loader: {
        isLoader: false,
        loadingCount: 0
    },
    notification: {}, // FYI: notification structure - {[notificationId]: {notificationId, isOpened, notificationType}} (09.05.2022, Slava)
    popup: {}, // FYI: popup structure - {[id]: {isOpened, id, modalType, children}} (08.05.2021, Slava)
    formData: {} // FYI: Data for FinalForm. Structure - {[form]: {...}} (23.11.2021, Slava)
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case START_LOADING: {
            const isLoader = state.loader.isLoader || payload.isLoader; // FYI: checking for pending request with loader (04.08.20, Vlad)
            const loadingCount = state.loader.loadingCount + 1;
            const loader = {isLoader, loadingCount};

            return {...state, loader};
        }

        case FINISH_LOADING: {
            const loadingCount = state.loader.loadingCount - 1;
            const isLoader = state.loader.isLoader && !!loadingCount; // FYI: checking for request without loader (13.04.20, Oleh)
            const loader = {isLoader, loadingCount};

            return {...state, loader};
        }

        case OPEN_POPUP: {
            const {id} = payload;
            const popup = {...state.popup, [id]: {...payload, isOpened: true}};

            return {...state, popup};
        }

        case CLOSE_POPUP: {
            const {id} = payload;
            const popup = {...state.popup, [id]: {}};

            return {...state, popup};
        }

        case SHOW_NOTIFICATION: {
            const {notificationId} = payload;
            const notification = {...state.notification, [notificationId]: {...payload, isOpened: true}};

            return {...state, notification};
        }

        case REMOVE_NOTIFICATION: {
            const {notificationId} = payload;
            const updatedNotification = {...state.notification};
            // FYI: Each time when we open Notification it will be absolutely new Notification. That's why we have to remove it totally (09.06.2022, Slava)
            delete updatedNotification[notificationId];

            return {...state, notification: updatedNotification};
        }

        case SET_FORM_DATA: {
            const {formData} = payload;

            return {...state, formData: {...state.formData, ...formData}};
        }

        default:
            return state;
    }
};
