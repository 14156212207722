import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Office from '../Office';
import {FieldArray} from '../../shared/FormComponents';
import ScoreCard from '../../shared/ScoreCard';
import {setActiveOffice} from '../../../actions/providers';
import {getActiveOffice} from '../../../selectors/providers';
import {formatAddress, getOfficeName, getFilledWorkingDay} from '../../../helpers';
import {equal, getDayNames} from '../../../utils';

const generateBaseOfficeData = () => ({
    name: '',
    location: {street_address: ''},
    working_hours: getDayNames().map(getFilledWorkingDay()),
    networks: [],
    hidden_networks: []
});

const getOfficeOption = source => (office, index) => {
    return ({label: `${getOfficeName(office, index)} (${source}), score: ${office?.confidence || '-'}`, value: index});
};

const Offices = props => {
    const {source, isCustomerAddressDisplayed, isOfficeEditable} = props;
    const dispatch = useDispatch();
    const activeOffice = useSelector(getActiveOffice);

    return (
        <FieldArray name='offices'>
            {({fields}) => {
                const officesOptions = fields.value.map(getOfficeOption(source));
                const onChangeOffice = value => dispatch(setActiveOffice(value));
                const activeOfficeConfidence = fields?.value[activeOffice]?.confidence;
                const activeOfficeAddress = fields?.value[activeOffice]?.address;
                const onAddOffice = () => {
                    fields.push(generateBaseOfficeData());
                    dispatch(setActiveOffice(fields.length));
                };

                return (
                    <ContentSection>
                        <Heading className='mb-12' type={HEADING_TYPES['4']}>Office Details</Heading>
                        <Row middle='xs'>
                            <Column sm={7} className='mb-12'>
                                <Text type={TEXT_TYPES.bodyBold}>{activeOfficeAddress}</Text>
                            </Column>
                            <Column sm={3} className='mb-12'>
                                <ScoreCard value={activeOfficeConfidence} title='Confidence' icon='thumbsUp'/>
                            </Column>
                        </Row>
                        <Row middle='xs' className='mb-5'>
                            <Column sm={9} className='mr-10'>
                                <Autocomplete value={activeOffice} options={officesOptions} onChange={onChangeOffice}/>
                            </Column>

                            <Column>
                                <Button type={BUTTON_TYPES.primary} disabled={!isOfficeEditable} onClick={onAddOffice}>Add office</Button>
                            </Column>

                            <Separator type='solid'/>
                        </Row>

                        {fields.map((field, index) => {
                            const isActive = equal(index, activeOffice);
                            const {location, networks, hidden_networks: hiddenNetworks} = fields.value[index];
                            // TODO: get wesiteURL and push it as props Seva(12.15.20223)
                            const officeProps = {
                                prefixName: field,
                                officeFullAddress: formatAddress(location ?? {}),
                                isOfficeEditable,
                                networks,
                                hiddenNetworks,
                                currentOfficeIndex: index,
                                isCustomerAddressDisplayed
                            };

                            return isActive && (
                                <Office {...officeProps}/>
                            );
                        })}
                    </ContentSection>
                );
            }}
        </FieldArray>
    );
};

Offices.propTypes = {
    source: PropTypes.string,
    isCustomerAddressDisplayed: PropTypes.bool,
    isOfficeEditable: PropTypes.bool
};

export {Offices as TestableOffices};
export default React.memo(Offices);
