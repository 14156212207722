import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Select from '@frontend/ui-kit/Components/Select';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import withPopup from '../../../HOC/withPopup';
import {requestNotGoodOptionList, requestNotGoodOptionAdding, requestNotGoodOptionUpdating, requestNotGoodOptionDeleting} from '../../../actions/providers';
import {getActiveNpi, getNotGoodOptions as getNotGoodOptionList} from '../../../selectors/providers';
import {equal, compose, isEmpty, negateFunc, getEqual} from '../../../utils';
import {BAD_OPTIONS} from '../../../options';

const POPUP_ID = 'notGoodOptionsPopup';

const NotGoodOptions = props => {
    const {openPopup, closePopup} = props;
    const dispatch = useDispatch();
    const activeNpi = useSelector(getActiveNpi);
    const notGoodOptionList = useSelector(getNotGoodOptionList);
    const [notGoodOptions, setNotGoodOptions] = useState([]);
    const {id: lastNotGoodOptionId} = notGoodOptions[notGoodOptions.length - 1] ?? {};
    const isAddableNotGoodOptions = lastNotGoodOptionId || isEmpty(notGoodOptions);

    useEffect(() => {
        (async () => {
            if (!activeNpi) {
                return;
            }

            const {notGoodOptions} = notGoodOptionList[activeNpi] ? {notGoodOptions: notGoodOptionList[activeNpi]} : await dispatch(requestNotGoodOptionList(activeNpi));

            setNotGoodOptions(notGoodOptions);
        })();
    }, [activeNpi, dispatch]);

    const getNotGoodOption = (notGoodOption, index) => {
        const {id: notGoodOptionId, comment, reasons} = notGoodOption;
        const isLast = equal(index, notGoodOptions.length - 1);
        const isSavingAvailable = comment && !isEmpty(reasons);

        const onChangeReason = value => {
            const updatedNotGoodOptions = notGoodOptions.map((notGoodOption => (
                {...notGoodOption, reasons: equal(notGoodOption.id, notGoodOptionId) ? value : notGoodOption.reasons}
            )));

            setNotGoodOptions(updatedNotGoodOptions);
        };

        const onChangeComment = ({target}) => {
            const {value} = target;
            const updatedNotGoodOptions = notGoodOptions.map((notGoodOption => (
                {...notGoodOption, comment: equal(notGoodOption.id, notGoodOptionId) ? value : notGoodOption.comment}
            )));

            setNotGoodOptions(updatedNotGoodOptions);
        };

        const onSaveNotGoodOption = async () => {
            const {notGoodOption: updatedNotGoodOption} = notGoodOptionId
                ? await dispatch(requestNotGoodOptionUpdating(activeNpi, notGoodOptionId, {comment, reasons}))
                : await dispatch(requestNotGoodOptionAdding(activeNpi, {comment, reasons}));
            const updatedNotGoodOptions = notGoodOptions.map(notGoodOption => (
                {...(!notGoodOption.id || equal(notGoodOption.id, notGoodOptionId) ? updatedNotGoodOption : notGoodOption)}
            ));

            setNotGoodOptions(updatedNotGoodOptions);
        };

        const onOpenDeletingPopup = () => {
            const onDeleteNotGoodOption = async () => {
                const {isSuccess} = await dispatch(requestNotGoodOptionDeleting(activeNpi, notGoodOptionId));

                closePopup();

                if (isSuccess) {
                    const filteredNotGoodOptions = notGoodOptions.filter(negateFunc(getEqual(notGoodOptionId, 'id')));

                    setNotGoodOptions(filteredNotGoodOptions);
                }
            };

            const actionBar = (
                <React.Fragment>
                    <Button type={BUTTON_TYPES.primary} onClick={onDeleteNotGoodOption}>Yes</Button>
                    <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                </React.Fragment>
            );

            const popupContent = <Text isInline>Are you sure you want to delete it?</Text>;
            const popupProps = {title: 'Delete not good option', actionBar, children: popupContent};
            const children = <PopupContent {...popupProps}/>;

            return openPopup({modalType: 'simple', children});
        };

        return (
            <div key={index} data-testid='not-good-option'>
                <Row>
                    <Column xs={12} className='mb-12'>
                        <Select value={reasons} label='Reasons' options={BAD_OPTIONS} isMulti onChange={onChangeReason}/>
                    </Column>
                    <Column xs={12}>
                        <Textarea value={comment} label='Comment' rows={3} maxLength={255} onChange={onChangeComment}/>
                    </Column>
                </Row>

                <Column xs={12}>
                    <Row end='xs'>
                        <Column sm={2}>
                            <Button type={BUTTON_TYPES.secondary} disabled={!isSavingAvailable} onClick={onSaveNotGoodOption}>Save</Button>
                        </Column>

                        {notGoodOptionId && (
                            <Column sm={2}>
                                <Button type={BUTTON_TYPES.destructive} onClick={onOpenDeletingPopup}>Delete</Button>
                            </Column>
                        )}
                    </Row>
                </Column>

                {!isLast && <Separator/>}
            </div>
        );
    };

    const onAddNotGoodOption = () => setNotGoodOptions([...notGoodOptions, {comment: '', reasons: []}]);

    return (
        <ContentSection>
            <Row between='xs' middle='xs' className='mb-16'>
                <Column className='mb-12'>
                    <Heading type={HEADING_TYPES['4']}>Not a good option</Heading>
                </Column>
            </Row>

            {!isEmpty(notGoodOptions) && (
                <div className='mb-10'>
                    {notGoodOptions.map(getNotGoodOption)}
                </div>
            )}

            <Row center='xs'>
                <Column sm={3}>
                    <Button type={BUTTON_TYPES.secondary} disabled={!isAddableNotGoodOptions} onClick={onAddNotGoodOption}>
                        Add new
                    </Button>
                </Column>
            </Row>
        </ContentSection>
    );
};

NotGoodOptions.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {NotGoodOptions as TestableNotGoodOptions};
export default compose(
    React.memo,
    withPopup(POPUP_ID)
)(NotGoodOptions);
