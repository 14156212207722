import React from 'react';
import PropTypes from 'prop-types';
import {Field as SimpleField} from 'react-final-form';
import useForm from '../../../../hooks/useForm';
import {equal, getEqual, pass} from '../../../../utils';

const Field = ({children, onChange, parse, format, ...restProps}) => {
    const form = useForm();
    const isEqual = (currValue, initialValue) => {
        return [initialValue, currValue].every(value => [null, undefined, ''].some(getEqual(value))) || equal(initialValue, currValue);
    };
    const enhancedParse = (...args) => parse(...args, form);
    const enhancedFormat = (...args) => format(...args, form);

    return (
        <SimpleField {...restProps} parse={enhancedParse} format={enhancedFormat} isEqual={isEqual}>
            {props => {
                // eslint-disable-next-line react/prop-types
                const {input, meta, ...restProps} = props;
                // eslint-disable-next-line react/prop-types,no-unused-vars
                const {checked, ...restInput} = input;
                // eslint-disable-next-line react/prop-types
                const {valid: isValid, error: errorMessage, submitError: submissionErrorMessage, touched: isTouched} = meta;

                return children({
                    ...restInput,
                    ...restProps,
                    isValid,
                    errorMessage: errorMessage || submissionErrorMessage,
                    isTouched,
                    onChange: event => {
                        if (onChange) {
                            onChange(event, form);
                        }

                        if (!event?.defaultPrevented) {
                            // eslint-disable-next-line react/prop-types
                            input.onChange(event);
                        }
                    }
                });
            }}
        </SimpleField>
    );
};

Field.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    allowNull: PropTypes.bool,
    type: PropTypes.string,
    format: PropTypes.func,
    parse: PropTypes.func,
    onChange: PropTypes.func
};

Field.defaultProps = {
    parse: pass,
    format: pass
};

export default Field;
