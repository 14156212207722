import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Popup from '@frontend/ui-kit/Components/Popup';
import {closePopup, openPopup} from '../../actions/shared';
import {getPopupState} from '../../selectors/shared';

const withPopup = popupId => Component => {
    return props => {
        const location = useLocation();
        const popupState = useSelector(getPopupState);
        const {isOpened} = popupState[popupId] || {};

        const dispatch = useDispatch();
        const onOpen = params => dispatch(openPopup({id: popupId, ...params}));
        const onClose = () => dispatch(closePopup({id: popupId}));

        const onDestroy = () => isOpened ? onClose() : false;
        useEffect(() => {
            onDestroy();

            return () => onDestroy();
        }, [location]);

        const popupProps = {...popupState[popupId], popupId, onClose};
        const componentProps = {...props, openPopup: onOpen, closePopup: onClose};

        return (
            <React.Fragment>
                <Popup {...popupProps}/>
                <Component {...componentProps}/>
            </React.Fragment>
        );
    };
};

export default popupId => withPopup(popupId);
