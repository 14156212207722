import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import {requestNetworks} from '../../../actions/providers';
import {promisifyAsyncFunction, getItemKeyValue, getEqual} from '../../../utils';
import {AUTOCOMPLETE_MIN_LENGTH} from '../../../constants';

const NetworksAutocomplete = props => {
    const {networks, disabled, onChange, formatValue} = props;
    const dispatch = useDispatch();
    const [foundedNetworks, setFoundedNetwork] = useState([]);

    const loadOptions = promisifyAsyncFunction(async query => {
        if (query.length < AUTOCOMPLETE_MIN_LENGTH) {
            return;
        }

        const {networks} = await dispatch(requestNetworks(query));
        setFoundedNetwork(networks);

        return networks.map(({name: label}) => ({label, value: label}));
    });

    const onChangeNetworks = values => {
        const networksNames = values ?? [];
        const updatedNetworks = networksNames.map(networkName => {
            return [...networks, ...foundedNetworks].find(getEqual(networkName, 'name'));
        });

        onChange(updatedNetworks);
    };

    const asyncAutocompleteProps = {
        ...props,
        value: networks.map(getItemKeyValue('name')),
        isMulti: true,
        isCreatable: false,
        isClearable: false,
        disabled,
        placeholder: 'Please, enter at least 3 characters',
        formatValue,
        loadOptions,
        onChange: onChangeNetworks
    };

    return <AsyncAutocomplete {...asyncAutocompleteProps}/>;
};

NetworksAutocomplete.propTypes = {
    networks: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number
    })),
    disabled: PropTypes.bool,
    formatValue: PropTypes.func,
    onChange: PropTypes.func.isRequired
};

NetworksAutocomplete.defaultProps = {
    networks: [],
    disabled: false,
    formatValue: value => ({label: value, value})
};

export {NetworksAutocomplete as TestableNetworksAutocomplete};
export default React.memo(NetworksAutocomplete);
