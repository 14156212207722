import React from 'react';
import PropTypes from 'prop-types';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import classnames from 'classnames';
import styles from './index.module.scss';

const TYPES = {
    thumbsUp: 'thumbsUp',
    starFull: 'starFull',
    care: 'care',
    moneyCouple: 'moneyCouple'
};

const ICON_BY_TYPE = {
    [TYPES.thumbsUp]: ICON_TYPES.thumbsUp,
    [TYPES.starFull]: ICON_TYPES.starFull,
    [TYPES.care]: ICON_TYPES.care,
    [TYPES.moneyCouple]: ICON_TYPES.moneyCouple
};

const BADGES_TYPES = [
    {min: 4.5, className: styles['score-card-badge__success']},
    {min: 4, className: styles['score-card-badge__secondary']},
    {min: 3, className: styles['score-card-badge__warning']},
    {min: 0, className: styles['score-card-badge__danger']}
];

const getBadgeClassName = value => {
    const badgeClass = BADGES_TYPES.find(({min}) => value >= min);
    return badgeClass ? badgeClass.className : styles['score-card-badge__secondary'];
};

const ScoreCard = ({icon, title, value}) => {
    const badgeClassName = getBadgeClassName(value);

    return (
        <div className={classnames(styles['score-card'])}>
            <div className={classnames(styles['score-card-badge'], badgeClassName)}>
                <Icon type={ICON_BY_TYPE[icon]} className={classnames(styles['score-card-badge__icon'])}/>
                <Text type={TEXT_TYPES.bodyBold} className={classnames(styles['score-card-badge__value'])}>{value || '-'}</Text>
            </div>
            <Text type={TEXT_TYPES.bodyBold}>{title}</Text>
        </div>
    );
};

ScoreCard.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
};

export {ScoreCard as TestableScoreCard};
export default ScoreCard;
