import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Collapse from '@frontend/ui-kit/Components/Collapse';

const CollapsedCellContent = ({value}) => {
    const [isOpen, setIsOpen] = useState(false);
    const networkItemsCount = value.split(',').map(element => element.trim()).length;

    const onInitiatorClick = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    };

    return (
        <Collapse className='white-space-normal'
            content={value || '-'}
            initiator={(
                <Button type={BUTTON_TYPES.tertiary} onClick={onInitiatorClick}>
                    {isOpen ? 'Hide all ' : 'View all '}
                    (<span> {networkItemsCount}</span>)
                    <Icon type={ICON_TYPES.arrowRight}/>
                </Button>
            )}
            isOpen={isOpen}/>
    );
};

CollapsedCellContent.propTypes = {
    value: PropTypes.string
};

CollapsedCellContent.defaultProps = {
    value: ''
};

export default CollapsedCellContent;
