import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import history from './history';
import Pages from './Pages';
import withAuthorizationControl from './HOC/withAuthorizationControl';
import {ROUTES} from './constants';

const RouteInnerAuthorization = withAuthorizationControl(Route);

const Router = () => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <RouteInnerAuthorization path={`${ROUTES.providerRecords}/:npi`} component={Pages.ProviderRecords}/>
                <RouteInnerAuthorization path={`${ROUTES.decisionCenter}/:journeyId`} component={Pages.DecisionCenter}/>
                <RouteInnerAuthorization path={`${ROUTES.PHAProfile}`} component={Pages.PHAProfile}/>

                <Route path='*' component={Pages.PageNotFound}/>
            </Switch>
        </ConnectedRouter>
    );
};

export default React.memo(Router);
