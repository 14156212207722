import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ROOT_ELEMENT, SENTRY_DSN, ENV} from './constants';
import './index.scss';
import '@frontend/ui-kit/index.css';

ReactDOM.render(<App/>, ROOT_ELEMENT);

// third-party libs initializing
if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0,
        environment: ENV
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
