import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {login} from '../../actions/authorization';

const withAuthorizationControl = Component => {
    return props => {
        const [isLoaded, setIsLoaded] = useState(false);
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(login());

            setIsLoaded(true);
        }, [dispatch]);

        return isLoaded ? <Component {...props}/> : null;
    };
};

export {withAuthorizationControl as testableWithAuthorizationControl};
export default withAuthorizationControl;
