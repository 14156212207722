import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classnames from 'classnames';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import {requestProviderRecordToTicketAdding} from '../../../actions/providers';

// TODO: Update component after we will discuss how will work with "assignedJourneys" (Slava, 04.06.2021)
const RecordToTicket = ({npi, className}) => {
    const [journeyId, setJourneyId] = useState();
    const dispatch = useDispatch();

    const onChangeAssignedJourney = value => setJourneyId(value);

    const onAddProviderRecordToTicket = () => {
        dispatch(requestProviderRecordToTicketAdding({journeyId, npi}));
    };

    return (
        <div className='record-to-ticket'>
            <ContentSection className={classnames('record-to-ticket', className)}>
                <Select value={journeyId} options={[]} onChange={onChangeAssignedJourney} label='Ticket ID'/>

                <Row>
                    <Column sm={4}>
                        <Button className='record-to-ticket__button mt-6' type={BUTTON_TYPES.primary} disabled={!journeyId} onClick={onAddProviderRecordToTicket}>
                            Push to DC
                        </Button>
                    </Column>
                </Row>
            </ContentSection>
        </div>
    );
};

RecordToTicket.propTypes = {
    npi: PropTypes.string,
    className: PropTypes.string
};

RecordToTicket.defaultProps = {
    className: ''
};

export {RecordToTicket as TestableRecordToTicket};
export default React.memo(RecordToTicket);
