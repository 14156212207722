import React from 'react';
import PropTypes from 'prop-types';
import Select from '@frontend/ui-kit/Components/Select';
import {LANGUAGES} from '../../../constants';

const LanguageSelector = props => {
    const {locale, onChange} = props;

    return <Select value={locale} options={LANGUAGES} onChange={onChange} label='Langue selector'/>;
};

LanguageSelector.propTypes = {
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export {LanguageSelector as TestableLanguageSelector};
export default React.memo(LanguageSelector);
