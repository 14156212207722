import React from 'react';
import PropTypes from 'prop-types';
import Column from '@frontend/ui-kit/Components/Column';
import ProviderForm from '../../Provider/ProviderForm';
import ServiceDetails from '../../Provider/ServiceDetails';

const AppointmentFormLayout = ({children}) => {
    return (
        <React.Fragment>
            <Column sm={5}>
                {children}

                <ServiceDetails/>
            </Column>
            <Column sm={7}>
                <ProviderForm isCustomerAddressDisplayed/>
            </Column>
        </React.Fragment>
    );
};

AppointmentFormLayout.propTypes = {
    children: PropTypes.node
};

export {AppointmentFormLayout as TestableAppointmentFormLayout};
export default AppointmentFormLayout;
