import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import classnames from 'classnames';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import WorkingDays from '../WorkingDays';
import {Field} from '../../shared/FormComponents';
import NetworksAutocomplete from '../../shared/NetworksAutocomplete';
import DistanceChecking from '../../shared/DistanceChecking';
import LabeledTooltip from '../../shared/LabeledTooltip';
import {setJourneyAddress} from '../../../actions/decisionCenter';
import {getJourneyAddress} from '../../../selectors/decisionCenter';
import useForm from '../../../hooks/useForm';
import {DEFAULT_PHONE_PLACEHOLDER, NETWORK_COLORS, NETWORK_BACKGROUND_COLORS} from '../../../constants';
import {equal, getEqual, getItemKeyValue, omit} from '../../../utils';
import {OFFICE_TYPE_OPTIONS, STATE_POSTAL_CODE_OPTIONS} from '../../../options';
import styles from './index.module.scss';

const JOURNEY_ADDRESS_TOOLTIP_CONTENT = 'Changes will be applied only when Decision is saved';

const getFormattedNetwork = providerNetworks => ({network_id: networkId, is_expired: isExpired}) => ({
    name: providerNetworks[networkId]?.name,
    id: networkId,
    is_expired: !!isExpired
});

const getFormattedOfficeNetwork = ({id, last_confirmed_at: lastConfirmedAt, is_expired: isExpired}) => ({
    network_id: id,
    last_confirmed_at: lastConfirmedAt ?? null,
    is_expired: !!isExpired
});

const networkColorStyles = {
    multiValueLabel: (styles, {data}) => ({
        ...styles,
        color: data.color,
        backgroundColor: data.backgroundColor
    })
};

const formatNetworkValue = networks => value => {
    const {is_expired: isExpired} = networks.find(getEqual(value, 'name')) ?? {};

    return {
        label: value,
        value,
        color: NETWORK_COLORS.grey,
        backgroundColor: isExpired ? NETWORK_BACKGROUND_COLORS.expired : NETWORK_BACKGROUND_COLORS.active
    };
};

const Office = ({prefixName, officeFullAddress, currentOfficeIndex, networks, hiddenNetworks, isCustomerAddressDisplayed, isOfficeEditable}) => {
    const dispatch = useDispatch();
    const form = useForm();
    const {values} = form.getState();
    const {networks: providerNetworks, offices} = values;
    const {fax_number: currentFaxNumber, website_url: currentWebsiteUrl} = offices[currentOfficeIndex];

    const journeyAddress = useSelector(getJourneyAddress);
    const [isWebsiteUrl, setIsWebsiteUrl] = useState(false);
    const [isFaxNumber, setIsFaxNumber] = useState(false);

    const onShowWebsiteUrlInput = () => {
        setIsWebsiteUrl(true);
    };

    const onShowFaxNumberInput = () => {
        setIsFaxNumber(true);
    };

    const onChangeJourneyAddress = ({target}) => {
        dispatch(setJourneyAddress(target.value));
    };

    const onChangeNetworks = fieldName => values => {
        const formattedOfficeNetworks = values.map(getFormattedOfficeNetwork);
        const joinedProviderNetworks = {...providerNetworks, ...(values.reduce((acc, network) => ({...acc, [network.id]: network}), {}))};

        // FYI: For check, we should take opposite network field, for example if we are changing "networks" field, should take "hidden_networks" field (Slava, 19.08.2022)
        const oppositeOfficeNetworks = equal(fieldName, 'networks') ? hiddenNetworks : networks;
        // FYI: Should collect each office networks, but exclude current Office, because we are working with it right now (Slava, 19.08.2022)
        const otherOfficesNetworks = offices.reduce((acc, {networks, hidden_networks: hiddenNetworks}, index) => !equal(index, currentOfficeIndex) ? [...acc, ...networks, ...hiddenNetworks] : acc, []);
        const officesNetworksIds = [...formattedOfficeNetworks, ...oppositeOfficeNetworks, ...otherOfficesNetworks].map(getItemKeyValue('network_id'));
        const providerNetworksIds = Object.keys(joinedProviderNetworks).map(Number);
        const foundNetworkId = providerNetworksIds.find(providerNetworkId => !officesNetworksIds.includes(providerNetworkId));

        const updatedProviderNetworks = foundNetworkId ? omit(joinedProviderNetworks, [`${foundNetworkId}`]) : joinedProviderNetworks;

        form.change(`${prefixName}.${fieldName}`, formattedOfficeNetworks);
        form.change('networks', updatedProviderNetworks);
    };

    return (
        <div className='office'>
            <Heading className='mb-12' type={HEADING_TYPES['5']}>General</Heading>

            <Row className='mb-10'>
                <Column className='mb-6' xs={12}>
                    <Field name={`${prefixName}.is_active`}>
                        {props => <Checkbox {...props} data-testid='is_active' disabled={!isOfficeEditable} caption='Office is active'/>}
                    </Field>
                </Column>
            </Row>

            <Row>
                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.name`}>
                        {props => <Input {...props} label='Office name' data-testid='name' disabled={!isOfficeEditable}/>}
                    </Field>
                </Column>

                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.phone_number`}>
                        {props => <Input {...props} label='Phone number' data-testid='phone_number' type='tel' disabled={!isOfficeEditable} placeholder={DEFAULT_PHONE_PLACEHOLDER}/>}
                    </Field>
                </Column>
            </Row>

            <Row>
                <Column sm={6}>
                    <Row>
                        <Column sm={4} className='mb-12'>
                            <Field name={`${prefixName}.location.zip_code`}>
                                {props => <Input {...props} label='ZIP' data-testid='zip_code' disabled={!isOfficeEditable}/>}
                            </Field>
                        </Column>
                        <Column sm={4} className='mb-12'>
                            <Field name={`${prefixName}.location.state`}>
                                {props => <Select {...props} label='State' data-testid='state' disabled={!isOfficeEditable} options={STATE_POSTAL_CODE_OPTIONS}/>}
                            </Field>
                        </Column>
                        <Column sm={4} className='mb-12'>
                            <Field name={`${prefixName}.location.city`}>
                                {props => <Input {...props} label='City' data-testid='city' disabled={!isOfficeEditable}/>}
                            </Field>
                        </Column>
                    </Row>
                </Column>
                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.location.street_address`}>
                        {props => <Input {...props} label='Street address' data-testid='street_address' disabled={!isOfficeEditable}/>}
                    </Field>
                </Column>
            </Row>

            <Row>
                <Column sm={6} className='mb-12'>
                    <Row>
                        {/* TODO fix in scope https://healthjoy.atlassian.net/browse/COM-1750 (Seva 18.12.23)
                        don't use ternary operator into markup */}
                        {(currentFaxNumber || isFaxNumber) ? (
                            <Column sm={6} className='mb-12'>
                                <Field name={`${prefixName}.fax_number`}>
                                    {props => <Input {...props} label='Fax' data-testid='fax_number' type='tel' disabled={!isOfficeEditable} placeholder={DEFAULT_PHONE_PLACEHOLDER}/>}
                                </Field>
                            </Column>
                        )
                            : (<Button type={BUTTON_TYPES.tertiary} disabled={!isOfficeEditable} onClick={onShowFaxNumberInput}>+ Add office fax</Button>)}
                        {/* TODO fix in scope https://healthjoy.atlassian.net/browse/COM-1750 (Seva 18.12.23)
                        don't use ternary operator into markup */}
                        {(currentWebsiteUrl || isWebsiteUrl) ? (
                            <Column sm={6}>
                                <Field name={`${prefixName}.website_url`}>
                                    {props => <Input {...props} label='Website' data-testid='website_url' disabled={!isOfficeEditable} maxLength={255}/>}
                                </Field>
                            </Column>
                        )
                            : (<Button onClick={onShowWebsiteUrlInput} disabled={!isOfficeEditable} type={BUTTON_TYPES.tertiary}>+ Add office website</Button>)}
                    </Row>
                </Column>
                <Column sm={6}>
                    <Text isInline isSmall className='mr-6'>Check distance between office and member: </Text>
                    <DistanceChecking className={styles['office-distance-checking']} startPoint={journeyAddress} endPoint={officeFullAddress}/>
                </Column>
            </Row>

            {isCustomerAddressDisplayed && (
                <Row data-testid='journey-address-layout' className='mt-10'>
                    <Column sm={6} className='mb-12'>
                        <Input value={journeyAddress}
                            label={<LabeledTooltip title='Customer address' content={JOURNEY_ADDRESS_TOOLTIP_CONTENT}/>}
                            data-testid='journey_address'
                            placeholder='Enter address...'
                            onChange={onChangeJourneyAddress}/>
                    </Column>

                    {!journeyAddress && (
                        <Column sm={12} data-testid='journey-address-validation-message' className='mb-12'>
                            <Text className={classnames(styles['office-journey-initial-address__warning'])} isInline isSmall>
                                Please, enter a valid address!
                            </Text>
                        </Column>
                    )}
                </Row>
            )}

            <Separator/>

            <Heading className='mb-6' type={HEADING_TYPES['5']}>Office traits</Heading>
            <Row>
                <Column xs={3} className='mb-12'>
                    <Field name={`${prefixName}.traits.parking`}>
                        {props => <Checkbox {...props} data-testid='parking' disabled={!isOfficeEditable} caption='Parking'/>}
                    </Field>
                </Column>
                <Column xs={3} className='mb-12'>
                    <Field name={`${prefixName}.traits.in_house_lab`}>
                        {props => <Checkbox {...props} data-testid='in_house_lab' disabled={!isOfficeEditable} caption='In house lab'/>}
                    </Field>
                </Column>
                <Column xs={3} className='mb-12'>
                    <Field name={`${prefixName}.traits.surgery_location`}>
                        {props => <Checkbox {...props} data-testid='surgery_location' disabled={!isOfficeEditable} caption='Surgery location'/>}
                    </Field>
                </Column>
                <Column xs={3} className='mb-12'>
                    <Field name={`${prefixName}.traits.doctor_office`}>
                        {props => <Checkbox {...props} data-testid='doctor_office' disabled={!isOfficeEditable} caption='Doctor office'/>}
                    </Field>
                </Column>
            </Row>

            <Separator/>

            <WorkingDays prefixName={prefixName} disabled={!isOfficeEditable}/>

            <Separator type='solid'/>

            <Heading className='mb-6' type={HEADING_TYPES['5']}>Office type</Heading>
            <Row className='mb-12'>
                <Column sm={8} className='mb-12'>
                    <Field name={`${prefixName}.type`}>
                        {props => <RadioGroup {...props} isInline label='' data-testid='type' disabled={!isOfficeEditable} options={OFFICE_TYPE_OPTIONS}/>}
                    </Field>
                </Column>

                <Column sm={12}>
                    <Field name={`${prefixName}.is_address_confirmed`}>
                        {props => <Checkbox {...props} data-testid='is_address_confirmed' disabled={!isOfficeEditable} caption='Address confirmed'/>}
                    </Field>
                </Column>
            </Row>

            <Separator/>

            <Row>
                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.networks`}>
                        {({value, ...restProps}) => {
                            const formattedValue = value.map(getFormattedNetwork(providerNetworks));

                            return (
                                <NetworksAutocomplete {...restProps}
                                    label='Networks'
                                    data-testid='networks'
                                    styles={networkColorStyles}
                                    networks={formattedValue}
                                    formatValue={formatNetworkValue(formattedValue)}
                                    disabled={!isOfficeEditable}
                                    onChange={onChangeNetworks('networks')}/>
                            );
                        }}
                    </Field>
                </Column>

                <Column sm={6} className='mb-12'>
                    <Field name={`${prefixName}.hidden_networks`}>
                        {({value, ...restProps}) => {
                            const formattedValue = value.map(getFormattedNetwork(providerNetworks));

                            return (
                                <NetworksAutocomplete {...restProps}
                                    label='Confirmed Out of Network'
                                    data-testid='hidden_networks'
                                    networks={formattedValue}
                                    disabled={!isOfficeEditable}
                                    onChange={onChangeNetworks('hidden_networks')}/>
                            );
                        }}
                    </Field>
                </Column>
            </Row>
        </div>
    );
};

Office.propTypes = {
    currentOfficeIndex: PropTypes.number,
    prefixName: PropTypes.string.isRequired,
    officeFullAddress: PropTypes.string.isRequired,
    networks: PropTypes.arrayOf(PropTypes.shape({
        network_id: PropTypes.number,
        last_confirmed_at: PropTypes.string,
        is_expired: PropTypes.bool
    })),
    hiddenNetworks: PropTypes.arrayOf(PropTypes.shape({
        network_id: PropTypes.number,
        last_confirmed_at: PropTypes.string,
        is_expired: PropTypes.bool
    })),
    isOfficeEditable: PropTypes.bool.isRequired,
    isCustomerAddressDisplayed: PropTypes.bool.isRequired
};

export {Office as TestableOffice};
export default React.memo(Office);
