import React, {useState, useRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {delay} from '../../../utils';
import styles from './index.module.scss';

const TOOLTIP_DELAY = 3000;
// TODO fix in scope https://healthjoy.atlassian.net/browse/COM-1750 (Seva 18.12.23)
// Update naming TooltipContent, it's a state for showing tooltip
const CopyClip = ({tooltipContent, textToCopy, className}) => {
    const inputRef = useRef(null);
    const [TooltipContent, setTooltipContent] = useState(null);

    const onCopyClick = () => {
        inputRef.current.value = textToCopy;

        inputRef.current.select();
        document.execCommand('copy');

        window.getSelection().removeAllRanges();

        setTooltipContent(tooltipContent);
        delay(() => setTooltipContent(null), TOOLTIP_DELAY);
    };

    return (
        <div onClick={onCopyClick} className={className}>
            <Tooltip isOpened={!!TooltipContent} content={tooltipContent}>
                <Row middle='xs' className={classnames('white-space-normal')}>
                    <Column sm={12}>
                        <span>{textToCopy}</span>
                        &nbsp;
                        {textToCopy !== '-' && <Icon className={styles['copy-clip__icon']} type={ICON_TYPES.copy}/>}
                    </Column>
                </Row>
            </Tooltip>
            {/* TODO fix in scope https://healthjoy.atlassian.net/browse/COM-1750 (Seva 18.12.23)
                        better move to styles file */}
            <textarea ref={inputRef}
                style={{position: 'absolute', left: '-9999px'}}
                readOnly/>
        </div>
    );
};

CopyClip.propTypes = {
    tooltipContent: PropTypes.string,
    textToCopy: PropTypes.string,
    className: PropTypes.string
};

export {CopyClip as TestableCopyClip};
export default CopyClip;
