import React from 'react';
import PropTypes from 'prop-types';
import Decisions from '../Decisions';
import withDecisionCenterForm from '../../../HOC/withDecisionCenterForm';
import {DECISION_KINDS, DECISION_REASON_FIELD_MAX_LENGTH} from '../../../constants';
import {validateRequired, validateInt, compose, validateMaxLength, getTextFromHtml} from '../../../utils';

/* istanbul ignore next */
const validateWalletCardDecision = ({details}) => {
    const {walletCardId} = details;

    return {
        details: {
            walletCardId: validateRequired(walletCardId)
        }
    };
};

/* istanbul ignore next */
const validateProviderDecision = ({details}) => {
    const {office_ids: officeIds, why_recommend: whyRecommend, procedure} = details;
    const {
        average_price_min: averagePriceMin,
        average_price_max: averagePriceMax,
        estimated_price_min: estimatedPriceMin,
        estimated_price_max: estimatedPriceMax
    } = procedure ?? {};

    return {
        details: {
            why_recommend: validateRequired(whyRecommend),
            office_ids: validateRequired(officeIds),
            procedure: {
                average_price_min: averagePriceMin || averagePriceMax ? (validateRequired(averagePriceMin) || validateInt(averagePriceMin)) : undefined,
                average_price_max: averagePriceMax ? (validateInt(averagePriceMax)) : undefined,
                estimated_price_min: estimatedPriceMin || estimatedPriceMax ? (validateInt(estimatedPriceMin) || validateRequired(estimatedPriceMin)) : undefined,
                estimated_price_max: estimatedPriceMax ? (validateRequired(estimatedPriceMax) || validateInt(estimatedPriceMax)) : undefined
            }
        }
    };
};

const VALIDATE_DECISION_BY_KIND = {
    [DECISION_KINDS.provider]: validateProviderDecision,
    [DECISION_KINDS.walletCard]: validateWalletCardDecision
};

/* istanbul ignore next */
const validate = values => {
    const {resolution, steerage_type_id: steerageTypeId, decisions = []} = values;
    const {reason, title} = resolution ?? {};

    return {
        steerage_type_id: validateRequired(steerageTypeId),
        resolution: {
            reason: validateMaxLength(getTextFromHtml(reason, true), DECISION_REASON_FIELD_MAX_LENGTH) || validateRequired(reason),
            title: validateRequired(title)
        },
        decisions: decisions.map(({kind, ...restDecision}) => kind && VALIDATE_DECISION_BY_KIND[kind](restDecision))
    };
};

const ProviderDecisionForm = ({decisionType}) => {
    const decisionsProps = {
        decisionType,
        isRemovable: true,
        areMultiOfficesIncluded: true
    };

    return <Decisions {...decisionsProps}/>;
};

ProviderDecisionForm.propTypes = {
    decisionType: PropTypes.string
};

export {ProviderDecisionForm as TestableProviderDecisionForm};
export default compose(
    withDecisionCenterForm({validate, isTabsValidation: true}),
    React.memo
)(ProviderDecisionForm);
