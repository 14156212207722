import ProviderRecords from './ProviderRecords';
import DecisionCenter from './DecisionCenter';
import PHAProfile from './PHA/Profile';
import PageNotFound from './PageNotFound';

export default {
    ProviderRecords,
    DecisionCenter,
    PHAProfile,
    PageNotFound
};
